import * as React from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import styles from '../../styles/Contratacion.module.css';
import '@inveert/ui-kit/src/button/button';
import '@inveert/ui-kit/src/icon/icon';
import '@inveert/ui-kit/src/text/text-xxxl';
import '@inveert/ui-kit/src/text/text-xxl';
import '@inveert/ui-kit/src/text/text-xl';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-m';
import '@inveert/ui-kit/src/text/text-s';
import '@inveert/ui-kit/src/text/text-xs';
import '@inveert/ui-kit/src/text/text-xxs';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/dropdown/dropdown';
import '@inveert/ui-kit/src/form/label/label';
import '@inveert/ui-kit/src/input/input-text';
import '@inveert/ui-kit/src/input/input-date';
import '@inveert/ui-kit/src/input/input-checkbox';

import SolidDividers from '../../elements/dividers/SolidDividers';
import {
    catalogObjectToArray,
    getCatalogLabelByValue,
    getErrorMessage,
    ibanValidator,
    tr,
    validateDniNifNie,
} from '../../utils/functions';
import {
    getUserInfoCatalogsService,
    postLoginFinanbestService,
    postRegistrationFinanbestService,
} from '../../modules/contract/contract.service';
import DotStepsFragment from '../../elements/fragments/DotStepsFragment';
import {selectContract, setUserFormData} from '../../modules/contract/contract.slice';
import {getUserDataService} from '../../modules/user/user.service';
import {addAlert} from '../../modules/app/app.slice';
import PageLoading from '../../elements/loading/PageLoading';

function InformacionUsuarioContratacion(props: {finanbestPass: string}) {
    const {finanbestPass} = props;
    const [userId, setUserId] = React.useState<null | number>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const router = useNavigate();
    const dispatch = useDispatch();
    const {userForm} = useSelector(selectContract);
    const {email} = useSelector(selectContract);
    const [catalogs, setCatalogs] = React.useState<Array<any>>([]);

    const validationSchema = yup.object({
        name: yup.string().max(20).required(tr('Introduce tu nombre')),
        lastName1: yup.string().max(20).required(tr('Introduce tu primer apellido')),
        lastName2: yup.string().max(20).required(tr('Introduce tu segundo apellido')),
        type_id: yup.string().nullable().required(tr('Dinos el tipo de documento')),
        document: yup
            .string()
            .nullable()
            .required(tr('Dinos tu numero de documento'))
            .test('documentValid', tr('Número de documento inválido'), (val) => validateDniNifNie(val)),
        gender: yup
            .string()
            .nullable()
            .required(tr('Introduce tu género'))
            .oneOf(['MALE', 'FEMALE'], tr('Introduce tu género')),
        expiration_date: yup
            .date()
            .typeError(tr('La fecha no es valida'))
            .nullable()
            .min(new Date(), tr('El documento no puede estar caducado'))
            .required(tr('Fecha de expiración requerida')),
        marital_status: yup.string().nullable().required(tr('Dinos tu estado civil')),
        mobile: yup
            .number()
            .typeError(tr('Tienes que introducir tu número de teléfono'))
            .required(tr('Tienes que introducir tu número de teléfono'))
            .max(999999999, tr('Teléfono no válido'))
            .min(100000000, tr('Teléfono no válido'))
            .nullable(),
        nationality: yup
            .string()
            .nullable()
            .required(tr('Dinos tu nacionalidad'))
            .oneOf(['España'], tr('Dinos en que país naciste')),
        birth_country: yup.string().nullable().required(tr('Dinos en que país naciste')),
        birth_region: yup.string().nullable().required(tr('En que region naciste')),
        birth_city: yup.string().nullable().required(tr('En que ciudad naciste')),
        varias_nacionalidades: yup.boolean(),
        primera_nacionalidad_extra: yup.string().nullable(),
        province: yup.string().nullable().required(tr('En que provincia vives')),
        postal_code: yup
            .number()
            .typeError(tr('Dinos tu código postal'))
            .nullable()
            .required(tr('Dinos tu código postal')),
        region: yup.string().nullable().required(tr('En que región vives')),
        city: yup.string().nullable().required(tr('En que ciudad vives')),
        address_type: yup.string().nullable().required(tr('Dinos el tipo de vía')),
        address: yup.string().nullable().required(tr('Dinos tu direccion')),
        address_number: yup.string().nullable().required(tr('dinos el numero')),
        address_floor: yup.string().nullable(),
        iban: yup
            .string()
            .nullable()
            .required(tr('Necesitamos saber el IBAN de tu cuenta'))
            .test('validateIban', tr('Número de cuenta inválido'), (val) => ibanValidator(val)),
        economic_activity: yup.string().nullable().required(tr('Necesitamos saber tu ocupación')),
        occupation: yup.string().nullable().required(tr('Necesitamos saber tu sector')),
        company: yup.string().nullable().required(tr('Dinos donde trabajas')),
    });

    const formik = useFormik({
        initialValues: {
            gender: userForm.gender ?? null,
            name: userForm.name ?? '',
            lastName1: userForm.lastName1 ?? '',
            lastName2: userForm.lastName2 ?? '',
            type_id: userForm.type_id ?? null,
            document: userForm.document ?? '',
            expiration_date: userForm.expiration_date ?? null,
            marital_status: userForm.marital_status ?? null,
            mobile: userForm.mobile ?? null,
            nationality: 'España',
            birth_country: userForm.birth_country ?? null,
            birth_region: userForm.birth_region ?? '',
            birth_city: userForm.birth_city ?? '',
            varias_nacionalidades: false,
            primera_nacionalidad_extra: null,
            province: userForm.province ?? '',
            postal_code: userForm.postal_code ?? null,
            region: userForm.region ?? '',
            city: userForm.city ?? '',
            address_type: userForm.address_type ?? null,
            address: userForm.address ?? '',
            address_number: userForm.address_number ?? '',
            address_floor: userForm.address_floor ?? '',
            iban: userForm.iban ?? '',
            economic_activity: userForm.economic_activity ?? null,
            occupation: userForm.occupation ?? null,
            company: userForm.company ?? '',
        },
        validationSchema,
        validateOnBlur: true,
        onSubmit: (values, actions) => {
            const splitedDate = values.expiration_date.split('-');
            const parsedExpirationDate = `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`;
            const processedData = {
                email: email ?? '',
                last_name_1: values.lastName1 ?? '',
                last_name_2: values.lastName2 ?? '',
                password: finanbestPass,
                mobile: values.mobile,
                document: values.document,
                type_id: values.type_id ?? '',
                gender: values.gender,
                country: values.nationality,
                birth_country: values.birth_country,
                birth_city: values.birth_city,
                birth_region: values.birth_region,
                postal_code: values.postal_code,
                region: values.region,
                city: values.city,
                population: values.city,
                province: values.province,
                municipality: values.city,
                nationality: values.nationality,
                expiration_date: parsedExpirationDate,
                address_type: values.address_type,
                address: values.address,
                address_number: values.address_number,
                address_floor: values.address_floor,
                residence_country: values.nationality,
                marital_status: values.marital_status,
                iban: values.iban,
                job: values.occupation ?? '',
                laboral: values.economic_activity ?? '',
                varias_nacionalidades: values.varias_nacionalidades,
                primera_nacionalidad_extra: values.primera_nacionalidad_extra ?? undefined,
            };

            postRegistrationFinanbestService(processedData, userId ?? 0)
                .then(() => {
                    postLoginFinanbestService(userId ?? 0, email, finanbestPass)
                        .then(() => {
                            router('/contratacion/cumplimiento-normativo');
                            actions.setSubmitting(false);
                        })
                        .catch(async (e) => {
                            const message = await getErrorMessage(e);
                            dispatch(
                                addAlert({
                                    message,
                                    isError: true,
                                    isOpen: true,
                                })
                            );
                            actions.setSubmitting(false);
                        });
                })
                .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                        addAlert({
                            message,
                            isError: true,
                            isOpen: true,
                        })
                    );
                    actions.setSubmitting(false);
                });
        },
    });
    const handleCheckboxChange = (e: any) => {
        const name = e.target.attributes.name.value;
        // @ts-ignore
        const value = !formik.values[name];
        formik
            // @ts-ignore
            .setFieldValue(name, value, false)
            // @ts-ignore
            .then(() => formik.validateField(name));
    };
    const handleRadioChange = (e: {target: {name: string; value: any}}) => {
        dispatch(setUserFormData({...userForm, [e.target.name]: e.target.value}));
        formik
            .setFieldValue(e.target.name, e.target.value, false)
            // @ts-ignore
            .then(() => formik.validateField(e.target.name));
    };
    const handleChange = (e: any) => {
        const name = e.target.attributes.name.value;
        // @ts-ignore
        if (formik.touched[name]) {
            formik
                .setFieldValue(name, e.detail.value, false)
                // @ts-ignore
                .then(() => formik.validateField(name));
        }
    };
    const handleBlur = (e: any) => {
        const name = e.target.attributes.name.value;
        dispatch(setUserFormData({...userForm, [name]: e.detail.value}));
        formik.setFieldTouched(name, true, false);
        // @ts-ignore
        formik
            .setFieldValue(name, e.detail.value, false)
            // @ts-ignore
            .then(() => formik.validateField(name));
    };
    React.useEffect(() => {
        // @ts-ignore
        window.addEventListener('input-text-change', handleChange);
        window.addEventListener('dropdown-focus-out', handleBlur);
        window.addEventListener('dropdown-change', handleChange);
        window.addEventListener('input-text-focus-out', handleBlur);
        window.addEventListener('input-date-focus-out', handleBlur);
        window.addEventListener('input-checkbox-change', handleCheckboxChange);
        return () => {
            // @ts-ignore
            window.removeEventListener('input-text-change', handleChange);
            window.removeEventListener('dropdown-change', handleChange);
            window.removeEventListener('dropdown-focus-out', handleBlur);
            window.removeEventListener('input-text-focus-out', handleBlur);
            window.removeEventListener('input-date-focus-out', handleBlur);
            window.removeEventListener('input-checkbox-change', handleCheckboxChange);
        };
    }, [handleCheckboxChange, handleChange, handleBlur]);
    React.useEffect(() => {
        setLoading(true);
        const storage = globalThis?.sessionStorage;
        const token = storage.getItem('token');
        if (!token) {
            router('/login');
        } else if (finanbestPass === '') {
            router('/contratacion/inicio');
        } else {
            const getCatalogsReq = getUserInfoCatalogsService();
            const getUserDataReq = getUserDataService(token);
            Promise.all([getCatalogsReq, getUserDataReq])
                .then(([catalogRes, userDataRes]) => {
                    const parsedCatalogs = catalogRes.map((catalog: {attributes: any; id: string}) => ({
                        attributes: catalogObjectToArray(catalog.attributes),
                        id: catalog.id,
                    }));
                    setCatalogs(parsedCatalogs);
                    setUserId(userDataRes.attributes.user_id);
                    setLoading(false);
                })
                .catch((e) => {
                    setLoading(false);
                    if (e?.response?.data?.message) {
                        dispatch(
                            addAlert({
                                message: e.response.data.message,
                                isError: true,
                                isOpen: true,
                            })
                        );
                    }
                });
        }
    }, []);
    const countriesOptions = JSON.stringify(
        catalogs?.find((catalog: {id: string}) => catalog.id === 'Countries')?.attributes
    );
    return (
        <div className={styles.container}>
            <PageLoading open={loading || formik.isSubmitting} />
            {catalogs.length > 0 && (
                <>
                    <inv-grid-row style={{justifyContent: 'center'}}>
                        <inv-grid-col class="col-8" style={{display: 'flex'}}>
                            <inv-text-xxxl
                                style={
                                    {
                                        '--inv-text-xxxl-gradient': 'var(--color-tertiary)',
                                        '--inv-text-xxxl-font-weight': 700,
                                        '--inv-text-xxxl-font-size': '2em',
                                        textAlign: 'center',
                                    } as React.CSSProperties
                                }
                            >
                                {tr('Información del cliente')}
                            </inv-text-xxxl>
                        </inv-grid-col>
                    </inv-grid-row>
                    <div style={{padding: '0 3.6em'}}>
                        <inv-grid-row style={{marginTop: '4.1em'}}>
                            <inv-grid-col class="col-12">
                                <inv-text-l
                                    style={{
                                        '--inv-text-l-font-weight': 600,
                                    }}
                                >
                                    {tr('Datos personales')}
                                </inv-text-l>
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row style={{marginTop: '3.2em'}}>
                            <inv-grid-col class="col">
                                <inv-input-text
                                    class="input input--primary"
                                    value={formik.values.name}
                                    name="name"
                                    error={formik.errors.name}
                                    label={tr('Tu nombre')}
                                    placeholder="Francisco"
                                />
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <inv-input-text
                                    class="input input--primary"
                                    value={formik.values.lastName1}
                                    name="lastName1"
                                    error={formik.errors.lastName1}
                                    label={tr('Primer apellido')}
                                    placeholder="García"
                                />
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <inv-input-text
                                    class="input input--primary"
                                    value={formik.values.lastName2}
                                    name="lastName2"
                                    error={formik.errors.lastName2}
                                    label={tr('Segundo apellido')}
                                    placeholder="López"
                                />
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row style={{marginTop: '1.6em'}}>
                            <inv-grid-col class="col">
                                <div style={{marginBottom: '0.4em'}}>
                                    <inv-dropdown
                                        class="input input--primary"
                                        label="Tipo de documento"
                                        error={formik.errors.type_id}
                                        placeholder="Selecciona uno"
                                        value={formik.values.type_id}
                                        name="type_id"
                                        options={JSON.stringify(
                                            catalogs?.find(
                                                (catalog: {id: string}) => catalog.id === 'IdentityDocuments'
                                            )?.attributes
                                        )}
                                    />
                                </div>
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <inv-input-text
                                    class="input input--primary"
                                    name="document"
                                    value={formik.values.document}
                                    error={formik.errors.document}
                                    label={tr('Número de documento')}
                                    placeholder="18736459S"
                                />
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <inv-input-date
                                    class="input input--primary"
                                    value={formik.values.expiration_date}
                                    error={formik.errors.expiration_date}
                                    name="expiration_date"
                                    label={tr('Fecha de expiración')}
                                />
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row style={{marginTop: '1.6em'}}>
                            <inv-grid-col class="col">
                                <div style={{marginBottom: '0.4em'}}>
                                    <inv-dropdown
                                        class="input input--primary"
                                        label={tr('Estado civil')}
                                        error={formik.errors.marital_status}
                                        placeholder={tr('Selecciona uno')}
                                        value={getCatalogLabelByValue(
                                            catalogs,
                                            'MaritalStatus',
                                            formik.values.marital_status
                                        )}
                                        name="marital_status"
                                        options={JSON.stringify(
                                            catalogs?.find((catalog: {id: string}) => catalog.id === 'MaritalStatus')
                                                ?.attributes
                                        )}
                                    />
                                </div>
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <inv-input-text
                                    class="input input--primary"
                                    name="mobile"
                                    value={formik.values.mobile}
                                    error={formik.errors.mobile}
                                    label={tr('Teléfono')}
                                    placeholder="677770700"
                                />
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <inv-dropdown
                                    class="input input--primary"
                                    label={tr('Nacionalidad')}
                                    value={formik.values.nationality}
                                    name="nationality"
                                    options={countriesOptions}
                                    disabled
                                />
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row style={{marginTop: '1.6em'}}>
                            <inv-grid-col class="col">
                                <inv-dropdown
                                    class="input input--primary"
                                    placeholder={tr('País donde naciste')}
                                    name="birth_country"
                                    error={formik.errors.birth_country}
                                    label={tr('País donde naciste')}
                                    value={formik.values.birth_country}
                                    options={countriesOptions}
                                />
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <inv-input-text
                                    class="input input--primary"
                                    name="birth_region"
                                    value={formik.values.birth_region}
                                    error={formik.errors.birth_region}
                                    label={tr('Región donde naciste')}
                                    placeholder="Murcia"
                                />
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <inv-input-text
                                    class="input input--primary"
                                    name="birth_city"
                                    value={formik.values.birth_city}
                                    error={formik.errors.birth_city}
                                    label={tr('Ciudad donde naciste')}
                                    placeholder="Cartagena"
                                />
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row
                            style={{
                                justifyContent: 'center',
                                marginTop: '1.6em',
                                '--inv-grid-gutter-x': '0.6em',
                            }}
                        >
                            <inv-grid-col class="col">
                                <inv-grid-row style={{alignItems: 'center'}}>
                                    <inv-grid-col class="col-auto">
                                        <inv-input-checkbox
                                            name="varias_nacionalidades"
                                            style={{
                                                '--inv-form-checkbox-font-family': '26px',
                                            }}
                                        />
                                    </inv-grid-col>
                                    <inv-grid-col class="col-auto">
                                        <inv-text-l
                                            style={{
                                                '--inv-text-l-font-weight': 400,
                                                '--inv-text-l-color': 'var(--text-color-50)',
                                            }}
                                        >
                                            {tr('Tengo varias nacionalidades')}
                                        </inv-text-l>
                                    </inv-grid-col>
                                </inv-grid-row>
                            </inv-grid-col>
                        </inv-grid-row>
                        {formik.values.varias_nacionalidades === true && (
                            <inv-grid-row style={{marginTop: '1.6em'}}>
                                <inv-grid-col
                                    style={{
                                        width: 'calc(33.33333% - var(--inv-grid-gutter-x,1.5em)',
                                    }}
                                    class="col-4"
                                >
                                    <inv-dropdown
                                        class="input input--primary"
                                        label={tr('Otra nacionalidad')}
                                        error={formik.errors.primera_nacionalidad_extra}
                                        name="primera_nacionalidad_extra"
                                        placeholder={tr('Nacionalidad extra')}
                                        value={formik.values.primera_nacionalidad_extra}
                                        options={countriesOptions}
                                    />
                                </inv-grid-col>
                            </inv-grid-row>
                        )}
                        <inv-grid-row style={{marginTop: '3.2em'}}>
                            <inv-grid-col class="col-12">
                                <inv-text-l
                                    style={{
                                        '--inv-text-l-font-weight': 600,
                                    }}
                                >
                                    {tr('Cuenta para rescatar tu dinero')}
                                </inv-text-l>
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row style={{marginTop: '1.6em'}}>
                            <inv-grid-col
                                class="col-6"
                                style={{
                                    width: 'calc(50% - var(--inv-grid-gutter-x,1.5em)',
                                }}
                            >
                                <inv-input-text
                                    class="input input--primary"
                                    value={formik.values.iban}
                                    name="iban"
                                    error={formik.errors.iban}
                                    label={tr('IBAN de tu cuenta corriente')}
                                    placeholder="ES5501304518933950023822"
                                />
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row style={{marginTop: '3.2em'}}>
                            <inv-grid-col class="col-12">
                                <inv-text-l
                                    style={{
                                        '--inv-text-l-font-weight': 600,
                                    }}
                                >
                                    {tr('Datos personales')}
                                </inv-text-l>
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row
                            style={{
                                marginTop: '1.6em',
                                alignItems: 'center',
                            }}
                        >
                            <inv-grid-col class="col-auto" style={{marginRight: '7em'}}>
                                <inv-text-m
                                    style={{
                                        '--inv-text-m-font-weight': 400,
                                        '--inv-text-m-color': 'var(--text-color-50)',
                                    }}
                                >
                                    {tr('Selecciona tu género')}
                                </inv-text-m>
                            </inv-grid-col>
                            <inv-grid-col class="col-auto">
                                <RadioGroup
                                    row
                                    aria-labelledby="gender-radio-buttons-group"
                                    name="gender"
                                    value={formik.values.gender}
                                    onChange={handleRadioChange}
                                >
                                    <FormControlLabel
                                        sx={{marginRight: '2.3em'}}
                                        value="MALE"
                                        control={
                                            <Radio
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        height: '0.75em',
                                                        width: '0.75em',
                                                    },
                                                }}
                                            />
                                        }
                                        label={
                                            <inv-text-m
                                                style={{
                                                    '--inv-text-m-font-size': '0.8em',
                                                    '--inv-text-m-color':
                                                        formik.values.gender !== 'MALE'
                                                            ? 'var(--text-color-50)'
                                                            : undefined,
                                                }}
                                            >
                                                {tr('Hombre')}
                                            </inv-text-m>
                                        }
                                    />
                                    <FormControlLabel
                                        value="FEMALE"
                                        control={
                                            <Radio
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        height: '0.75em',
                                                        width: '0.75em',
                                                    },
                                                }}
                                            />
                                        }
                                        label={
                                            <inv-text-m
                                                style={{
                                                    '--inv-text-m-font-size': '0.8em',
                                                    '--inv-text-m-color':
                                                        formik.values.gender !== 'FEMALE'
                                                            ? 'var(--text-color-50)'
                                                            : undefined,
                                                }}
                                            >
                                                {tr('Mujer')}
                                            </inv-text-m>
                                        }
                                    />
                                </RadioGroup>
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row>
                            <inv-grid-col>
                                <inv-form-label
                                    style={{
                                        '--inv-form-label-color': 'var(--error-color)',
                                    }}
                                >
                                    {formik.errors.gender}
                                </inv-form-label>
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row style={{marginTop: '1.6em'}}>
                            <inv-grid-col
                                class="col-2"
                                style={{
                                    width: 'calc(16.66666% - var(--inv-grid-gutter-x,1.5em))',
                                }}
                            >
                                <div style={{marginBottom: '0.4em'}}>
                                    <inv-dropdown
                                        class="input input--primary"
                                        label={tr('Tipo de via')}
                                        error={formik.errors.address_type}
                                        placeholder={tr('Selecciona uno')}
                                        value={getCatalogLabelByValue(
                                            catalogs,
                                            'AddressType',
                                            formik.values.address_type
                                        )}
                                        name="address_type"
                                        options={JSON.stringify(
                                            catalogs?.find((catalog: {id: string}) => catalog.id === 'AddressType')
                                                ?.attributes
                                        )}
                                    />
                                </div>
                            </inv-grid-col>
                            <inv-grid-col
                                class="col-4"
                                style={{
                                    width: 'calc(33.33333% - var(--inv-grid-gutter-x,1.5em))',
                                }}
                            >
                                <inv-input-text
                                    class="input input--primary"
                                    value={formik.values.address}
                                    name="address"
                                    error={formik.errors.address}
                                    label={tr('Nombre de la calle')}
                                    placeholder="De la Vega"
                                />
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <inv-input-text
                                    class="input input--primary"
                                    value={formik.values.address_number}
                                    name="address_number"
                                    error={formik.errors.address_number}
                                    label={tr('Número')}
                                    placeholder="25"
                                />
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <inv-input-text
                                    class="input input--primary"
                                    value={formik.values.address_floor}
                                    name="address_floor"
                                    error={formik.errors.address_floor}
                                    label={tr('Piso')}
                                    placeholder="3A"
                                />
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row style={{marginTop: '1.6em'}}>
                            <inv-grid-col
                                class="col-2"
                                style={{
                                    width: 'calc(16.66666% - var(--inv-grid-gutter-x,1.5em))',
                                }}
                            >
                                <inv-input-text
                                    class="input input--primary"
                                    name="postal_code"
                                    value={formik.values.postal_code}
                                    error={formik.errors.postal_code}
                                    label={tr('Código postal')}
                                    placeholder="11100"
                                />
                            </inv-grid-col>
                            <inv-grid-col
                                class="col-4"
                                style={{
                                    width: 'calc(33.33333% - var(--inv-grid-gutter-x,1.5em))',
                                }}
                            >
                                <div style={{marginBottom: '0.4em'}}>
                                    <inv-dropdown
                                        class="input input--primary"
                                        label={tr('Población')}
                                        error={formik.errors.city}
                                        placeholder={tr('Selecciona una')}
                                        search
                                        value={formik.values.city}
                                        name="city"
                                        options={JSON.stringify(
                                            catalogs?.find((catalog: {id: string}) => catalog.id === 'Municipalities')
                                                ?.attributes
                                        )}
                                    />
                                </div>
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <div style={{marginBottom: '0.4em'}}>
                                    <inv-dropdown
                                        class="input input--primary"
                                        label={tr('Provincia')}
                                        error={formik.errors.province}
                                        placeholder={tr('Selecciona una')}
                                        search
                                        value={formik.values.province}
                                        name="province"
                                        options={JSON.stringify(
                                            catalogs?.find((catalog: {id: string}) => catalog.id === 'Provinces')
                                                ?.attributes
                                        )}
                                    />
                                </div>
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <div style={{marginBottom: '0.4em'}}>
                                    <inv-dropdown
                                        class="input input--primary"
                                        label={tr('Comunidad Autónoma')}
                                        error={formik.errors.region}
                                        placeholder={tr('Selecciona una')}
                                        search
                                        value={formik.values.region}
                                        name="region"
                                        options={JSON.stringify(
                                            catalogs?.find((catalog: {id: string}) => catalog.id === 'Regions')
                                                ?.attributes
                                        )}
                                    />
                                </div>
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row style={{marginTop: '3.2em'}}>
                            <inv-grid-col class="col-12">
                                <inv-text-l
                                    style={{
                                        '--inv-text-l-font-weight': 600,
                                    }}
                                >
                                    {tr('Datos profesionales')}
                                </inv-text-l>
                            </inv-grid-col>
                        </inv-grid-row>
                        <inv-grid-row style={{marginTop: '3.2em'}}>
                            <inv-grid-col class="col">
                                <div style={{marginBottom: '0.4em'}}>
                                    <inv-dropdown
                                        label={tr('Ocupación')}
                                        error={formik.errors.economic_activity}
                                        placeholder={tr('Selecciona una')}
                                        value={formik.values.economic_activity}
                                        name="economic_activity"
                                        options={JSON.stringify(
                                            catalogs?.find((catalog: {id: string}) => catalog.id === 'Labor')
                                                ?.attributes
                                        )}
                                    />
                                </div>
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <div style={{marginBottom: '0.4em'}}>
                                    <inv-dropdown
                                        label={tr('Sector')}
                                        error={formik.errors.occupation}
                                        placeholder={tr('Selecciona uno')}
                                        value={formik.values.occupation}
                                        name="occupation"
                                        options={JSON.stringify(
                                            catalogs?.find((catalog: {id: string}) => catalog.id === 'Occupations')
                                                ?.attributes
                                        )}
                                    />
                                </div>
                            </inv-grid-col>
                            <inv-grid-col class="col">
                                <inv-input-text
                                    class="input input--primary"
                                    value={formik.values.company}
                                    name="company"
                                    error={formik.errors.company}
                                    label={tr('Empresa en la que trabajas')}
                                    placeholder={tr('Nombre de tu empresa')}
                                />
                            </inv-grid-col>
                        </inv-grid-row>
                    </div>

                    <inv-grid-row style={{marginTop: '5.2em', padding: ''}}>
                        <inv-grid-col class="col-12">
                            <div
                                style={{
                                    maxWidth: 'calc(100% - var(--inv-grid-gutter-x,1.5em))',
                                }}
                            >
                                <SolidDividers orientation="horizontal" />
                            </div>
                        </inv-grid-col>
                    </inv-grid-row>
                    <inv-grid-row
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '1.2em 3.6em 0',
                        }}
                    >
                        <inv-grid-col class="col-2" />
                        <inv-grid-col class="col-auto" style={{zIndex: 1}}>
                            <DotStepsFragment steps={3} activeStep={0} />
                        </inv-grid-col>
                        <inv-grid-col class="col-2">
                            <inv-button class="button button--primary-contained" onClick={() => formik.submitForm()}>
                                {tr('Continuar')}
                            </inv-button>
                        </inv-grid-col>
                    </inv-grid-row>
                </>
            )}
        </div>
    );
}

InformacionUsuarioContratacion.layout = 'EmptyLayout';
InformacionUsuarioContratacion.mobileLayout = 'MobileEmptyLayout';
export default InformacionUsuarioContratacion;
