import * as React from 'react';
import {userFinancialWealthMockedData, userMockedData} from '../user/user.data';
import {IRecommendation} from './propuesta.type';
import {tpl, tr} from '../../utils/functions';

export const settingsMockedData = {
    data: [
        {
            attributes: {
                description: 'Moneda a utilizar en la plataforma',
                name: 'CURRENCY',
                value: 'EUR',
            },
            id: '1',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Símbolo de la moneda a utilizar en la plataforma',
                name: 'CURRENCY_SYMBOL',
                value: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
            },
            id: '2',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Esperanza de vida estimada',
                name: 'DEATH_AGE',
                value: 90,
            },
            id: '3',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Rentabilidad que se aplica a los depósitos',
                name: 'DEPOSIT_PERFORMANCE',
                value: 0.3,
            },
            id: '4',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Rentabilidad esperada de la renta variable',
                name: 'EQUITY_EXPECTED_PERFORMANCE',
                value: [
                    -0.009967806906662102, 0.015361658921664967, 0.021881085220596286, 0.01392274380624281,
                    0.013455215956118427, 0.020289233686921815, 0.024579797775460318, 0.03012297156276178,
                    0.0382879797672836, 0.04041094437728122, 0.035589846456476615, 0.0396811674384161,
                    0.04152637848153756, 0.04149275072565084, 0.043064727529367186, 0.04309689907529268,
                    0.0442471755880493, 0.044943716432435006, 0.045560931634716584, 0.04537319815096286,
                ],
            },
            id: '5',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Rentabilidad máxima de la renta variable',
                name: 'EQUITY_MAX_PERFORMANCE',
                value: [
                    0.7402113462870843, 0.5356720039402925, 0.407622436935081, 0.30778149216635375, 0.3235294567606471,
                    0.24618595322949807, 0.24328981353928025, 0.19942670659171902, 0.17485493724944146,
                    0.16654044906324628, 0.15728379438476714, 0.14984965290587082, 0.15653629944435132,
                    0.14555560418309255, 0.14916314539325737, 0.1474306885991219, 0.14780019573374137,
                    0.14576424370330798, 0.13003191379511114, 0.13146993335086754,
                ],
            },
            id: '6',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Rentabilidad mínima de la renta variable',
                name: 'EQUITY_MIN_PERFORMANCE',
                value: [
                    -0.5152492404791729, -0.3186781024046671, -0.19717927017842618, -0.12835839761715895,
                    -0.08530827339560709, -0.02964944254472368, -0.0541523993544838, -0.06229435967891216,
                    -0.07286991215388905, -0.05107901339309883, -0.03571808678831667, -0.016439162480715197,
                    -0.007088283287054575, 0.005168835410180428, 0.0070299152202095705, 0.0038477147853819282,
                    0.012507019935633856, 0.0155410583921336, 0.013903830716451404, 0.0066801054336724786,
                ],
            },
            id: '7',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Rentabilidad que se aplica a la renta fija',
                name: 'FIXED_INCOME_PERFORMANCE',
                value: [
                    0.0016607175876643154, 0.006887639385974999, 0.00833336153066498, 0.009585462053922853,
                    0.010671440249263612, 0.011620485500669702, 0.012513644938243671, 0.01309284715711226,
                    0.013635031429851561, 0.013663122999868781, 0.013780201847900258, 0.013602189333090603,
                    0.013592813286765867, 0.013580427726305775, 0.013709549895365682, 0.0140062607097463,
                    0.014426400971303903, 0.015025232869069005, 0.01528733662431066, 0.015274802229017894,
                ],
            },
            id: '8',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Tasa de incremento de ahorro de objetivos',
                name: 'INCREMENT_OBJECTIVES',
                value: 0,
            },
            id: '9',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Tasa de inflación anual',
                name: 'INFLATION',
                value: 2,
            },
            id: '10',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Rentabilidad que se aplica a la renta fija',
                name: 'MAX_FIXED_INCOME_PERFORMANCE',
                value: [
                    0.044678564824671356, 0.03422472122805001, 0.03133327693867004, 0.028829075892154298,
                    0.026657119501472773, 0.024759028998660598, 0.022972710123512662, 0.02181430568577548,
                    0.02072993714029688, 0.02067375400026243, 0.020439596304199482, 0.020795621333818803,
                    0.02081437342646827, 0.020839144547388447, 0.020580900209268636, 0.019987478580507406,
                    0.019147198057392192, 0.017949534261861994, 0.017425326751378683, 0.01745039554196421,
                ],
            },
            id: '11',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Contribución máxima anual a los servicios de planes de pensiones',
                name: 'MAX_PENSION_PLAN_CONTRIBUTION',
                value: 1500,
            },
            id: '12',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Cantidad máxima que alguien puede recibir de una pensión del estado',
                name: 'MAX_RETIREMENT',
                value: 2819.18,
            },
            id: '13',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Vector de rentabilidades media que nos proporciona la entidad',
                name: 'MEAN_PERFORMANCES_VECTOR',
                value: [
                    0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016,
                    0.016, 0.016, 0.016, 0.016, 0.016, 0.016,
                ],
            },
            id: '14',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Rentabilidad que se aplica a la renta fija',
                name: 'MIN_FIXED_INCOME_PERFORMANCE',
                value: [
                    -0.012678564824671366, -0.002224721228050005, 0.0006667230613299613, 0.003170924107845705,
                    0.005342880498527227, 0.007240971001339401, 0.009027289876487342, 0.010185694314224523,
                    0.011270062859703124, 0.011326245999737567, 0.011560403695800517, 0.011204378666181198,
                    0.011185626573531731, 0.011160855452611553, 0.011419099790731363, 0.012012521419492593,
                    0.012852801942607807, 0.014050465738138009, 0.014574673248621318, 0.01454960445803579,
                ],
            },
            id: '15',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Cantidad mínima que alguien puede recibir de una pensión del estado',
                name: 'MIN_RETIREMENT',
                value: 685,
            },
            id: '16',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Rentabilidad que se aplica a los activos monetarios',
                name: 'MONETARY_PERFORMANCE',
                value: 0.23,
            },
            id: '17',
            type: 'Setting',
        },
        {
            attributes: {
                description:
                    'Esta variable indica el percentil que se está usando para obtener las rentabilidades de la renta variable',
                name: 'PERCENTILE',
                value: 25,
            },
            id: '18',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Tipo de recomendación de servicio que se dará después de la planificación',
                name: 'RECOMMENDATION_TYPE',
                value: 'ONE_SERVICE_PER_OBJECTIVE',
            },
            id: '19',
            type: 'Setting',
        },
        {
            attributes: {
                description:
                    'Esta variable indica si se puede aportar a los planes de pensiones o si simplemente se puede realizar un traspaso y rentabilizar.',
                name: 'SAVING_FOR_PENSION',
                value: 0,
            },
            id: '20',
            type: 'Setting',
        },
        {
            attributes: {
                description: 'Años de vida laboral',
                name: 'WORKING_YEARS',
                value: 25,
            },
            id: '21',
            type: 'Setting',
        },
    ],
    jsonapi: {
        version: '1.0',
    },
    links: {
        self: '/api/v2/setting/v1/settings',
    },
};

export const recommendationMockedData: Array<IRecommendation> = [
    /*
    {
        goal_ids: [1],
        attributes: {
            total_monthly_saving: 100,
            total_initial_investment: 1000,
            initial_investment: 1000,
            annual_percentage_rate: 0.006,
            fees_and_comissions_specification: 'entre 0.8% y 1.29%',
            financial_provider_id: 1,
            financial_service_type_id: 1,
            identifier: 'PGREY_RV',
            is_active: true,
            isr: false,
            management: 'gestión delegada',
            max_equity_exposure: 0.3,
            min_investment: 300,
            money_availability: 'Máx. una semana',
            name: 'Cartera Profile Grey',
            performance: 0.034,
            profile: '57bc752a3512085e4a8a7987',
            recommended_period: 4,
            risk: 'tranquilo',
            transfer_cost: 'sin coste',
            services: [
                {
                    attributes: {
                        geography: 'Global',
                        alias: 'PIMCO Global High Yield Bond',
                        asset: 'Renta Fija',
                        category: 'Renta Fija Corporativa Alto Rendimiento H I',
                        brochure:
                            'https://www.finanbest.com/wp-content/uploads/2020/09/KID_ES_es_IE00B2R34Y72_YES_2020-06-24.pdf',
                        currency: 'EUR',
                        description:
                            'El fondo trata de alcanzar su objetivo adquiriendo exposición a una gama diversificada de valores e instrumentos de renta fija de alta rentabilidad emitidos por empresas de todo el mundo. El fondo invertirá principalmente en valores «sin grado de inversión». Generalmente, se considera que estas inversiones son más arriesgadas que los valores con «grado de inversión», pero pueden generar ingresos más elevados. Es un fondo de gestión activa.',
                        identifier: 'IE00B0V9TC00',
                        name: 'PIMCO GIS Global High Yield Bond Fund',
                        weight: '0.3',
                        financial_product_id: 1,
                    },
                    id: '1',
                    type: 'FinancialProduct',
                },
                {
                    attributes: {
                        geography: 'Global',
                        alias: 'iShares Euro Investment Grade Corporate Bond Index Fund (IE) Inst EUR',
                        asset: 'Renta Fija',
                        category: 'Renta Fija Corporativa Grado de Inversión',
                        brochure:
                            'https://www.finanbest.com/wp-content/uploads/2020/06/KID_ES_es_IE00B67T5G21_YES_2020-02-12.pdf',
                        currency: 'EUR',
                        description:
                            'Este fondo invierte principalmente en bonos corporativos en euros con grado de inversión (Investment Grade) y con vencimientos de medio y largo plazo. Es un fondo indexado (gestión pasiva).',
                        identifier: 'IE00B67T5G21',
                        name: 'iShares Euro Inv Grd Corp Bd Idx Fd (IE)',
                        weight: '0.7',
                        financial_product_id: 2,
                    },
                    id: '3',
                    type: 'FinancialProduct',
                },
            ],
        },
        id: '4',
        type: 'FinancialService',
    },
*/
];

export const getRecommendationServiceMockedData = {
    data: [
        {
            attributes: {
                financial_service_id: 2,
                goal_id: 1,
                initial_investment: 1000,
            },
            id: '1',
            type: 'Recommendation',
        },
        {
            attributes: {
                financial_service_id: 7,
                goal_id: 1,
                initial_investment: 1000,
            },
            id: '2',
            type: 'Recommendation',
        },
        {
            attributes: {
                financial_service_id: 2,
                goal_id: 2,
                initial_investment: 1000,
            },
            id: '3',
            type: 'Recommendation',
        },
    ],
    included: [
        {
            attributes: {
                initial_investment: 1000,
                annual_percentage_rate: 0.006,
                fees_and_comissions_specification: 'entre 0.8% y 1.29%',
                financial_provider_id: 1,
                financial_service_type_id: 1,
                identifier: 'PGREY_RV',
                is_active: true,
                isr: false,
                management: 'gestión delegada',
                max_equity_exposure: 0.3,
                min_investment: 300,
                money_availability: 'Máx. una semana',
                name: 'Cartera Profile Grey',
                performance: 0.034,
                profile: '57bc752a3512085e4a8a7987',
                recommended_period: 4,
                risk: 'tranquilo',
                transfer_cost: 'sin coste',
            },
            id: '2',
            type: 'FinancialService',
        },
        {
            attributes: {
                initial_investment: 1000,
                annual_percentage_rate: 0.006,
                fees_and_comissions_specification: 'entre 0.8% y 1.29%',
                financial_provider_id: 1,
                financial_service_type_id: 2,
                identifier: 'PP_PRUDENTE',
                is_active: true,
                isr: false,
                management: 'gestión delegada',
                max_equity_exposure: 0.3,
                min_investment: 0,
                money_availability: 'Máx. una semana',
                name: 'Cartera Plan de Pensiones Prudente',
                performance: 0.03,
                profile: '5c07f4bd4cadba70ef856972',
                risk: 'tranquilo',
                transfer_cost: 'sin coste',
            },
            id: '7',
            type: 'FinancialService',
        },
    ],
    jsonapi: {
        version: '1.0',
    },
    links: {
        self: '/api/v2/plan/v1/plans/1/recommendations',
    },
};

export const getFinancialServicesServiceMockedData = {
    data: {
        attributes: {
            annual_percentage_rate: 0.006,
            fees_and_comissions_specification: 'entre 0.8% y 1.29%',
            financial_provider_id: 1,
            financial_service_type_id: 1,
            identifier: 'PYELLOW_RV',
            is_active: true,
            isr: false,
            management: 'gestión delegada',
            max_equity_exposure: 0.7,
            min_investment: 300,
            money_availability: 'Máx. una semana',
            name: 'Cartera Profile Yellow',
            performance: 0.066,
            profile: '57bc754f3512085e4a8a798b',
            recommended_period: 6,
            risk: 'decidido',
            transfer_cost: 'sin coste',
        },
        id: '4',
        type: 'FinancialService',
    },
    included: [
        {
            attributes: {
                description:
                    'Esta es la cartera de fondos de inversión recomendada para el inicio que te ayudará a alcanzar tu meta.',
                is_active: true,
                min_investment: 3000,
                name: 'Fondos de Inversión',
                type: 'MUTUAL',
            },
            id: '1',
            type: 'FinancialServiceType',
        },
        {
            attributes: {
                alias: 'PIMCO Global High Yield Bond',
                asset: 'Renta Fija',
                category: 'Renta Fija Corporativa Alto Rendimiento H I',
                geography: 'Global',
                brochure:
                    'https://www.finanbest.com/wp-content/uploads/2020/09/KID_ES_es_IE00B2R34Y72_YES_2020-06-24.pdf',
                currency: 'EUR',
                description:
                    'El fondo trata de alcanzar su objetivo adquiriendo exposición a una gama diversificada de valores e instrumentos de renta fija de alta rentabilidad emitidos por empresas de todo el mundo. El fondo invertirá principalmente en valores «sin grado de inversión». Generalmente, se considera que estas inversiones son más arriesgadas que los valores con «grado de inversión», pero pueden generar ingresos más elevados. Es un fondo de gestión activa.',
                identifier: 'IE00B0V9TC00',
                name: 'PIMCO GIS Global High Yield Bond Fund',
            },
            id: '1',
            type: 'FinancialProduct',
        },
        {
            attributes: {
                alias: 'iShares Euro Investment Grade Corporate Bond Index Fund (IE) Inst EUR',
                asset: 'Renta Fija',
                category: 'Renta Fija Corporativa Grado de Inversión',
                geography: 'Europa',
                brochure:
                    'https://www.finanbest.com/wp-content/uploads/2020/06/KID_ES_es_IE00B67T5G21_YES_2020-02-12.pdf',
                currency: 'EUR',
                description:
                    'Este fondo invierte principalmente en bonos corporativos en euros con grado de inversión (Investment Grade) y con vencimientos de medio y largo plazo. Es un fondo indexado (gestión pasiva).',
                identifier: 'IE00B67T5G21',
                name: 'iShares Euro Inv Grd Corp Bd Idx Fd (IE)',
            },
            id: '3',
            type: 'FinancialProduct',
        },
        {
            attributes: {
                alias: 'OSTRUM Euro Short Term Credit',
                asset: 'Renta Fija',
                category: 'Renta Fija Corp. Euro IG Corto Plazo',
                geography: 'Europa',
                brochure:
                    'https://www.finanbest.com/wp-content/uploads/2019/10/KID_ES_es_LU1118012159_YES_2020-02-19-OSTRUM.pdf',
                currency: 'EUR',
                description:
                    'Este fondo invierte principalmente en bonos corporativos en euros con grado de inversión (Investment Grade) y con vencimientos de entre uno y tres años. Es un fondo de gestión activa.',
                identifier: 'LU0935221761',
                name: 'Ostrum Euro Short Term Credit',
            },
            id: '4',
            type: 'FinancialProduct',
        },
        {
            attributes: {
                alias: 'Vanguard Global Stock Index',
                asset: 'Renta Variable',
                category: 'Renta Variable mercados desarrollados',
                geography: 'Global',
                brochure:
                    'https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE00B03HD191_YES_2020-02-18-VGD-Global.pdf',
                currency: 'EUR',
                description:
                    'El objetivo del Fondo es replicar el rendimiento del índice MSCI World Index, para obtener el retorno total de los mercados de renta variable de los mercados desarrollados. El Índice está formado por acciones de medianas y grandes empresas. Es un fondo indexado (gestión pasiva).',
                identifier: 'IE00B03HD191',
                name: 'VANGUARD GLOBAL STOCK INDEX',
            },
            id: '5',
            type: 'FinancialProduct',
        },
        {
            attributes: {
                alias: 'Vanguard Emerging Markets Stock Index',
                asset: 'Renta Variable',
                category: 'Renta Variable mercados emergentes',
                geography: 'Emergentes',
                brochure:
                    'https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE0031786696_YES_2020-02-18-VGD-EM.pdf',
                currency: 'EUR',
                description:
                    'El Fondo pretende replicar la rentabilidad del índice MSCI Emerging Markets Index. El Indice está formado por acciones de empresas de gran y mediana capitalización de mercados emergentes. Es un fondo indexado (gestión pasiva).',
                identifier: 'IE0031786696',
                name: 'VANGUARD EM MKTS STK INDX',
            },
            id: '6',
            type: 'FinancialProduct',
        },
        {
            attributes: {
                alias: 'Vanguard Global Small-Cap Index',
                asset: 'Renta Variable',
                category: 'Renta Variable Small Caps',
                geography: 'Global',
                brochure:
                    'https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE00B42W4L06_YES_2020-02-18-VGD-SC.pdf',
                currency: 'EUR',
                description:
                    'El Fondo pretende replicar la rentabilidad del índice MSCI World Small Cap. El Indice está formado por acciones de empresas de pequeña y mediana capitalización de los países desarrollados. Es un fondo indexado (gestión pasiva).',
                identifier: 'IE00B42W4L06',
                name: 'VANGUARD GB SM CAP IDX',
            },
            id: '7',
            type: 'FinancialProduct',
        },
        {
            attributes: {
                alias: 'M&G Emerging Markets Bond H',
                asset: 'Renta Fija',
                category: 'Renta Fija Mercados Emergentes H C',
                geography: 'Emergentes',
                brochure:
                    'https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_LU1670632170_YES_2020-02-12-MG.pdf',
                currency: 'EUR',
                description:
                    'El objetivo principal del fondo es obtener ingresos de una cartera de obligaciones  y otros valores de deuda de mercados emergentes de todo el mundo. Es un fondo de gestión activa.',
                identifier: 'LU1670632170',
                name: 'M&G (Lux) Em Mkts Bd C H EUR Acc',
            },
            id: '11',
            type: 'FinancialProduct',
        },
        {
            attributes: {
                financial_product_id: 1,
                financial_service_id: 4,
                weight: 0.14,
            },
            id: '4',
            type: 'FinancialProductFinancialService',
        },
        {
            attributes: {
                financial_product_id: 3,
                financial_service_id: 4,
                weight: 0.05,
            },
            id: '13',
            type: 'FinancialProductFinancialService',
        },
        {
            attributes: {
                financial_product_id: 4,
                financial_service_id: 4,
                weight: 0.05,
            },
            id: '17',
            type: 'FinancialProductFinancialService',
        },
        {
            attributes: {
                financial_product_id: 5,
                financial_service_id: 4,
                weight: 0.4,
            },
            id: '22',
            type: 'FinancialProductFinancialService',
        },
        {
            attributes: {
                financial_product_id: 6,
                financial_service_id: 4,
                weight: 0.2,
            },
            id: '26',
            type: 'FinancialProductFinancialService',
        },
        {
            attributes: {
                financial_product_id: 7,
                financial_service_id: 4,
                weight: 0.08,
            },
            id: '30',
            type: 'FinancialProductFinancialService',
        },
        {
            attributes: {
                financial_product_id: 11,
                financial_service_id: 4,
                weight: 0.08,
            },
            id: '39',
            type: 'FinancialProductFinancialService',
        },
    ],
    jsonapi: {
        version: '1.0',
    },
    links: {
        self: '/api/v2/plan/v1/financial-services/4',
    },
};

export const propuestaMockedData = {
    financialWealth: userFinancialWealthMockedData,
    user: userMockedData,
    withoutInveertChartMonthly: [
        {
            id: 7,
            data: [
                {
                    name: 1,
                    input: 142.71300805150943,
                    cumulative_input: 1712.556096618113,
                    init_date: '2022-05-02',
                    end_date: '2023-04-02',
                },
                {
                    name: 2,
                    input: 142.71300805150943,
                    cumulative_input: 3425.112193236226,
                    init_date: '2023-05-02',
                    end_date: '2024-04-02',
                },
                {
                    name: 3,
                    input: 142.71300805150943,
                    cumulative_input: 5137.668289854342,
                    init_date: '2024-05-02',
                    end_date: '2025-04-02',
                },
                {
                    name: 4,
                    input: 142.71300805150943,
                    cumulative_input: 6850.2243864724605,
                    init_date: '2025-05-02',
                    end_date: '2026-04-02',
                },
                {
                    name: 5,
                    input: 142.71300805150943,
                    cumulative_input: 8562.780483090577,
                    init_date: '2026-05-02',
                    end_date: '2027-04-02',
                },
                {
                    name: 6,
                    input: 142.71300805150943,
                    cumulative_input: 10275.336579708684,
                    init_date: '2027-05-02',
                    end_date: '2028-04-02',
                },
                {
                    name: 7,
                    input: 142.71300805150943,
                    cumulative_input: 11987.892676326792,
                    init_date: '2028-05-02',
                    end_date: '2029-04-02',
                },
                {
                    name: 8,
                    input: 142.71300805150943,
                    cumulative_input: 13700.4487729449,
                    init_date: '2029-05-02',
                    end_date: '2030-04-02',
                },
                {
                    name: 9,
                    input: 142.71300805150943,
                    cumulative_input: 15413.004869563007,
                    init_date: '2030-05-02',
                    end_date: '2031-04-02',
                },
                {
                    name: 10,
                    input: 142.71300805150943,
                    cumulative_input: 17125.560966181114,
                    init_date: '2031-05-02',
                    end_date: '2032-04-02',
                },
                {
                    name: 11,
                    input: 142.71300805150943,
                    cumulative_input: 18838.11706279922,
                    init_date: '2032-05-02',
                    end_date: '2033-04-02',
                },
                {
                    name: 12,
                    input: 142.71300805150943,
                    cumulative_input: 20550.67315941733,
                    init_date: '2033-05-02',
                    end_date: '2034-04-02',
                },
                {
                    name: 13,
                    input: 142.71300805150943,
                    cumulative_input: 22263.229256035436,
                    init_date: '2034-05-02',
                    end_date: '2035-04-02',
                },
                {
                    name: 14,
                    input: 142.71300805150943,
                    cumulative_input: 23975.785352653544,
                    init_date: '2035-05-02',
                    end_date: '2036-04-02',
                },
                {
                    name: 15,
                    input: 142.71300805150943,
                    cumulative_input: 25688.34144927165,
                    init_date: '2036-05-02',
                    end_date: '2037-04-02',
                },
                {
                    name: 16,
                    input: 142.71300805150943,
                    cumulative_input: 27400.89754588976,
                    init_date: '2037-05-02',
                    end_date: '2038-04-02',
                },
                {
                    name: 17,
                    input: 142.71300805150943,
                    cumulative_input: 29113.453642507866,
                    init_date: '2038-05-02',
                    end_date: '2039-04-02',
                },
                {
                    name: 18,
                    input: 142.71300805150943,
                    cumulative_input: 30826.009739125973,
                    init_date: '2039-05-02',
                    end_date: '2040-04-02',
                },
                {
                    name: 19,
                    input: 142.71300805150943,
                    cumulative_input: 32538.56583574408,
                    init_date: '2040-05-02',
                    end_date: '2041-04-02',
                },
                {
                    name: 20,
                    input: 142.71300805150943,
                    cumulative_input: 34251.12193236223,
                    init_date: '2041-05-02',
                    end_date: '2042-04-02',
                },
                {
                    name: 21,
                    input: 142.71300805150943,
                    cumulative_input: 35963.67802898038,
                    init_date: '2042-05-02',
                    end_date: '2043-04-02',
                },
                {
                    name: 22,
                    input: 142.71300805150943,
                    cumulative_input: 37676.23412559853,
                    init_date: '2043-05-02',
                    end_date: '2044-04-02',
                },
                {
                    name: 23,
                    input: 142.71300805150943,
                    cumulative_input: 39388.79022221668,
                    init_date: '2044-05-02',
                    end_date: '2045-04-02',
                },
                {
                    name: 24,
                    input: 142.71300805150943,
                    cumulative_input: 41101.34631883483,
                    init_date: '2045-05-02',
                    end_date: '2046-04-02',
                },
                {
                    name: 25,
                    input: 142.71300805150943,
                    cumulative_input: 42813.90241545298,
                    init_date: '2046-05-02',
                    end_date: '2047-04-02',
                },
                {
                    name: 26,
                    input: 142.71300805150943,
                    cumulative_input: 44526.458512071134,
                    init_date: '2047-05-02',
                    end_date: '2048-04-02',
                },
                {
                    name: 27,
                    input: 142.71300805150943,
                    cumulative_input: 46239.014608689286,
                    init_date: '2048-05-02',
                    end_date: '2049-04-02',
                },
                {
                    name: 28,
                    input: 142.71300805150943,
                    cumulative_input: 47951.57070530744,
                    init_date: '2049-05-02',
                    end_date: '2050-04-02',
                },
                {
                    name: 29,
                    input: 142.71300805150943,
                    cumulative_input: 49664.12680192559,
                    init_date: '2050-05-02',
                    end_date: '2051-04-02',
                },
                {
                    name: 30,
                    input: 142.71300805150943,
                    cumulative_input: 51376.68289854374,
                    init_date: '2051-05-02',
                    end_date: '2052-04-02',
                },
                {
                    name: 31,
                    input: 142.71300805150943,
                    cumulative_input: 53089.23899516189,
                    init_date: '2052-05-02',
                    end_date: '2053-04-02',
                },
                {
                    name: 32,
                    input: 142.71300805150943,
                    cumulative_input: 54801.79509178004,
                    init_date: '2053-05-02',
                    end_date: '2054-04-02',
                },
                {
                    name: 33,
                    input: 142.71300805150943,
                    cumulative_input: 56514.35118839819,
                    init_date: '2054-05-02',
                    end_date: '2055-04-02',
                },
                {
                    name: 34,
                    input: 142.71300805150943,
                    cumulative_input: 58226.90728501634,
                    init_date: '2055-05-02',
                    end_date: '2056-04-02',
                },
                {
                    name: 35,
                    input: 142.71300805150943,
                    cumulative_input: 59939.463381634494,
                    init_date: '2056-05-02',
                    end_date: '2057-04-02',
                },
                {
                    name: 36,
                    input: 142.71300805150943,
                    cumulative_input: 60795.74142994357,
                    init_date: '2057-05-02',
                    end_date: '2057-10-02',
                },
            ],
        },
        {
            id: 9,
            data: [
                {
                    name: 1,
                    input: 1514.7335614338374,
                    cumulative_input: 18176.80273720605,
                    init_date: '2022-05-02',
                    end_date: '2023-04-02',
                },
            ],
        },
    ],
    plan: {
        id: '327ec35c-f50e-4f28-8923-1408b761eb23',
        birthday: '1992-10-02T13:39:02.000000Z',
        gender: null,
        initial_investment: 3000,
        private_pension_plan: 5000,
        global_info: {
            initial_investment: 8000,
            saving: 80757.78544267055,
            yearly_saving: 22780.50517109021,
            monthly_saving: 1898.3754309241842,
            performance: 224339.32581053948,
            adjusted_objective: 313097.11125320994,
            remaining_saving: 305097.11125320994,
            equity: 0.557,
            fixed_income: 0.44299999999999995,
        },
        plan: [
            {
                initial_investment: 5300,
                yearly_saving: 1726.370628434155,
                monthly_saving: 143.86421903617958,
                adjusted_objective: 289554.9365457113,
                saving: 59703.65090001449,
                remaining_saving: 284254.9365457113,
                performance: 224551.2856456969,
                equity: 0.8,
                fixed_income: 0.2,
                min_performance: 0,
                max_performance: 0,
                objective: {
                    id: 7,
                    type: 'EDUCATION',
                    output_type: 'UNIQUE',
                    protection_type: 'CAPITAL',
                    name: 'Educación de sdfs',
                    amount: 23234,
                    duration: null,
                    duration_type: null,
                    init_date: '2023-03-15',
                    end_date: '2024-03-15',
                    is_active: true,
                    probability: null,
                    time_horizon: 0,
                    percentage_completed: null,
                    adjusted_objective: 23582.51,
                },
            },
            {
                initial_investment: 2700,
                yearly_saving: 21054.13454265606,
                monthly_saving: 1754.5112118880047,
                adjusted_objective: 23542.174707498645,
                saving: 21054.13454265606,
                remaining_saving: 20842.174707498645,
                performance: -211.95983515741452,
                equity: 0.08,
                fixed_income: 0.92,
                min_performance: 0,
                max_performance: 0,
                objective: {
                    id: 9,
                    type: 'RETIREMENT',
                    output_type: 'MONTHLY',
                    protection_type: 'LONG_TERM',
                    name: 'Complemento jubilación',
                    amount: 368.36,
                    duration: 312,
                    duration_type: 'MONTHLY',
                    init_date: '2056-10-02',
                    end_date: '2082-10-02',
                    is_active: true,
                    probability: null,
                    time_horizon: 35,
                    percentage_completed: null,
                    adjusted_objective: 232834.4853671255,
                },
            },
        ],
        created_at: '2022-03-03T11:44:24.000000Z',
        is_active: false,
        channel: null,
    },
    scenarios: [
        {
            objective: 7,
            scenario_data: {
                optimistic_TIR: 0.32942154793214495,
                optimistic_performance: 18533.316247386116,
                optimistic_amount: 5748.760145404498,
                expected_TIR: 0.08152625805336466,
                expected_performance: 3732.1621363549516,
                expected_amount: 13943.997471054992,
                pessimistic_TIR: 0.07112271996092612,
                pessimistic_performance: 3921.0731615840127,
                pessimistic_amount: 3203.9285033503866,
            },
        },
        {
            objective: 9,
            scenario_data: {
                optimistic_TIR: 0.3702081668183108,
                optimistic_performance: 10632.803727439597,
                optimistic_amount: 15384.281776293388,
                expected_TIR: -0.20286546248960824,
                expected_performance: 122.89896072079134,
                expected_amount: 7874.377009574583,
                pessimistic_TIR: -0.16317413677253512,
                pessimistic_performance: 717.8506785385698,
                pessimistic_amount: 5469.3287273923615,
            },
        },
    ],
    recommendation: [
        {
            objective: 9,
            services: [
                {
                    financial_service: {
                        type: 'mutual',
                        annual_percentage_rate: '0.0060',
                        liquidity: 'En cualquier momento',
                        taxation_when_selling: 'Base imponible del ahorro IRPF',
                        tax_advantage: 'Traspaso sin coste fiscal',
                    },
                    financial_products: [
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU1620157294',
                                name: 'BNP Paribas Funds Green Bond',
                                alias: 'BNP Paribas Green Bond',
                                description:
                                    'El fondo trata de incrementar el valor de sus activos a medio plazo invirtiendo en bonos verdes globales (Europa principalmente) que apoyen proyectos medioambientales, emitidos por organismos soberanos supranacionales y corporativos, entidades locales y/o gobiernos en divisas fuertes.\n\n                Se gestiona de manera activa y, como tal, puede invertir en valores que no estén incluidos en el índice Bloomberg Barclays MSCI Global Green Bond Index. El equipo de inversiones aplica asimismo la política de inversión sostenible de BNP Paribas AM, que tiene en cuenta los criterios ambientales, sociales y de gobernanza (ASG) en las inversiones del fondo.  El proceso de inversión filtra y excluye los bonos verdes con peor puntuación ASG, y procura evitar «green-washing» a través de una definición exigente del bono verde.\n                \n                El fondo obtuvo la acreditación ISR (inversión socialmente responsable) por parte del Ministerio de finanzas francés, y el sello GreenFin por parte del Ministerio francés de la Transición Ecológica y Solidária.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/BNP-Green-Bond-KID_ES_es_LU1620157294_YES_2020-02-19.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU1620157294_YES_2020-12-31.pdf',
                                category: 'Renta Fija Diversificada',
                                asset: 'Renta Fija',
                                weight: '0.09',
                            },
                            weight: 0.02,
                            saving: 99.95955882352948,
                            input: 99.95955882352948,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'IE00BD0DT792',
                                name: 'iShares Green Bond Index Fund',
                                alias: 'iShares Green Bond Index Fund',
                                description:
                                    'El fondo trata de obtener rentabilidad de su inversión mediante una combinación de revalorización del capital y rendimientos que refleje la rentabilidad del Bloomberg Barclays MSCI Green Bond Index, el índice de referencia del fondo (el Índice). El Fondo invierte principalmente en los valores de renta fija (RF) (como bonos) que componen el Índice, cuyos ingresos se utilizan para financiar proyectos con beneficios medioambientales directos como pueden ser por ejemplo la reducción de emisiones de CO2, el ahorro y tratamiento del agua, la preservación forestal, o la generación de energías renovables.\n\n                Es un fondo indexado (gestión pasiva).',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/iShares-Green-Bonds-KID_ES_es_IE00BD0DT792_YES_2020-02-12.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_IE00BD0DT792_YES_2020-11-30-1.pdf',
                                category: 'Renta Fija Diversificada',
                                asset: 'Renta Fija',
                                weight: '0.05',
                            },
                            weight: 0.01,
                            saving: 55.533088235294166,
                            input: 55.533088235294166,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU0966596875',
                                name: 'DPAM Bonds Emerging Markets Sustainable',
                                alias: 'DPAM Bonds Emerging Markets Sustainable',
                                description:
                                    'El fondo invierte principalmente en obligaciones y/u otros títulos de deuda denominados en cualquier divisa, emitidos (o garantizados) por países emergentes (lo que incluye sus administraciones públicas territoriales y sus organismos públicos —o asimilados—) o por organismos públicos internacionales (como el Banco Mundial y el Banco Europeo de Reconstrucción y Desarrollo) y seleccionados según criterios vinculados al desarrollo sostenible (como, por ejemplo, la igualdad social, el respeto por el medio ambiente y una gobernanza política y económica equitativa).\n\n                Se trata de un fondo gestionado activamente.\n                \n                El fondo obtuvo el sello de inversión sostenible belga Towards Sustainability y la acreditación LuxFLAG ESG. LuxFLAG es una organización sin ánimo de lucro internacional e independiente cuyo objetivo es promover la inversión sostenible. Reconoce aquellos fondos que incorporan el análisis de criterios y consideraciones medioambientales, sociales y de gobernanzas (ESG) en su proceso de inversión.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/DPAM-RF-EM-KID_ES_en_LU0966596875_YES_2020-02-19.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0966596875_YES_2020-11-30.pdf',
                                category: 'Renta Fija Países Emergentes',
                                asset: 'Renta Fija',
                                weight: '0.08',
                            },
                            weight: 0.02,
                            saving: 88.85294117647065,
                            input: 88.85294117647065,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU1644441476',
                                name: 'Candriam SRI Bond Global High Yield',
                                alias: 'Candriam SRI Bond Global High Yield',
                                description:
                                    'Este fondo de renta fija invierte en bonos de alto rendimiento («High Yield») de empresas internacionales. Los valores se seleccionan en función de un proceso de análisis económico-financiero, así como de criterios medioambientales, sociales y de gobierno corporativo (ASG). El análisis ASG combina un enfoque «Best-in-Class» con un proceso de exclusión de los emisores que incumplan con los principios del pacto mundial de las Naciones Unidas en materia de derechos humanos y laborales, medio-ambiente y lucha contra la corrupción y/o que estén expuestos a actividades controvertidas (armamento, carbón térmico, tabaco etc…). El fondo reporta un impacto positivo en los siguientes ámbitos (respecto a su benchmark): reducción de consumo de agua y de emisión de carbono, producción de energías renovables, programas de biodiversidad, y disminución de la generación de residuos.\n\n                El fondo obtuvo el sello ISR (inversión socialmente responsable) por parte del Ministerio de finanzas francés y el sello Towards Sustainability por parte del gobierno belga.\n                \n                Es un fondo de gestión activa.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/Candriam-HY-KID_ES_es_LU1644441476_YES_2020-07-21.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/CandriamSustainableBondGlobalHighYield_Rep_R_R_20201231-1.pdf',
                                category: 'Renta Fija Alto Rendimiento',
                                asset: 'Renta Fija',
                                weight: '0.12',
                            },
                            weight: 0.02,
                            saving: 133.27941176470597,
                            input: 133.27941176470597,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU1434524259',
                                name: 'Candriam SRI Equity Emerging Markets',
                                alias: 'Candriam SRI Equity Emerging Markets',
                                description:
                                    'El fondo invierte en acciones de empresas de países emergentes que cumplen con los diez principios del pacto mundial de la ONU y que no están involucradas en actividades controvertidas como por ejemplo el comercio de armas. El fondo excluye de sus inversiones las empresas que no cumplan con los mínimos criterios ASG, y enfoca sus inversiones en empresas que están bien posicionadas para afrontar los retos de la sostenibilidad global.\n\n                Los impactos ASG más significativos reportados por el fondo se concentra en los ahorros de emisión de carbono y de consumo de agua.\n                \n                Es un fondo de gestión activa.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/Candriam-RV-EM-KID_ES_es_LU1434524259_YES_2020-07-06.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/CandriamSustainableEquityEmergingMarkets_Rep_R_R_20201231.pdf',
                                category: 'Renta Variable Países Emergentes',
                                asset: 'Renta Variable',
                                weight: '0.20',
                            },
                            weight: 0.09,
                            saving: 457.72727272727315,
                            input: 457.72727272727315,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU1437020909',
                                name: 'Amundi Index MSCI Global Climate Change',
                                alias: 'Amundi Index MSCI Global Climate Change',
                                description:
                                    'El fondo pretende replicar la rentabilidad del Índice MSCI World Climate Change Index. Este índice está basado en el MSCI World Index («Índice base»), que incluye valores de gran y mediana capitalización de 23 países considerados mercados desarrollados. El objetivo del Índice consiste en representar la rentabilidad de una estrategia en la que se re-ponderan los valores en función de las oportunidades y los riesgos asociados a la transición hacia un modelo económico de menor consumo de carbono, y se procura reducir al mínimo las exclusiones del Índice base.\n\n                Es un fondo indexado (gestión pasiva).',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/Amundi-RV-Climate-Change-MR_ES_es_LU1437020909_RES_2020-07-31.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU1437020909_RES_2020-12-31.pdf',
                                category: 'Renta Variable Global',
                                asset: 'Renta Variable',
                                weight: '0.12',
                            },
                            weight: 0.05,
                            saving: 274.63636363636385,
                            input: 274.63636363636385,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU0348927095',
                                name: 'Nordea Global Climate & Environment Fund',
                                alias: 'Nordea Global Climate & Environment Fund',
                                description:
                                    'El objetivo del fondo consiste en proporcionar a los accionistas un crecimiento de la inversión a largo plazo. Al gestionar activamente la cartera del fondo, el equipo de gestión se centra en aquellas empresas que desarrollan soluciones respetuosas con el medioambiente y el clima, como las energías renovables y la eficiencia de los recursos, y que parecen ofrecer mejores perspectivas de crecimiento y características de inversión. El fondo invierte principalmente en títulos de renta variable de empresas de todo el mundo. El fondo también sigue una política de exclusión de empresas de algunos sectores controvertidos cómo pueden ser el carbón térmico y metalúrgico, el petróleo y gas convencionales y no convencionales, el armamento militar y nuclear, las armas convencionales y controvertidas, y el tabaco.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/Nordea-KID_ES_es_LU0348927095_YES_2020-06-25.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0348927095_YES_2020-12-31.pdf',
                                category: 'Renta Variable Sector Ecológico',
                                asset: 'Renta Variable',
                                weight: '0.12',
                            },
                            weight: 0.05,
                            saving: 274.63636363636385,
                            input: 274.63636363636385,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU0503631631',
                                name: 'Pictet Global Environmental Opportunities',
                                alias: 'Pictet Global Environmental Opportunities',
                                description:
                                    'El fondo invierte a nivel global y principalmente en acciones de empresas de energía limpia y agua, agricultura, silvicultura y otras áreas de la cadena de valor medioambiental. Es un fondo de gestión activa.\n\n                El fondo obtuvo el sello de inversión sostenible belga Towards Sustainability, la acreditación de ASG francesa Label ISR, y el FNG Label. Forum Nachhaltige Geldanlagen (FNG-Siegel), fundada en 2001, es la asociación de la industria que promueve la inversión sostenible en Alemania, Austria y Suiza. El objetivo de FNG es mejorar los estándares de calidad para productos de inversión sostenibles, para asegurar el cumplimiento de las normas mínimas internacionalmente reconocidas.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure: 'https://www.finanbest.com/wp-content/uploads/2020/08/Pictet-KID.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0503631631_YES_2020-12-31.pdf',
                                category: 'Renta Variable Sector Ecológico',
                                asset: 'Renta Variable',
                                weight: '0.08',
                            },
                            weight: 0.03,
                            saving: 183.09090909090926,
                            input: 183.09090909090926,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU0406803147',
                                name: 'BNP Paribas Funds Climate Impact',
                                alias: 'BNP Paribas Funds Climate Impact',
                                description:
                                    'El fondo trata de incrementar el valor de sus activos a medio plazo invirtiendo en acciones emitidas por empresas que operen centradas en permitir la adaptación al cambio climático o la mitigación de sus efectos (principalmente empresas europeas y estadounidenses de pequeña capitalización). Estas actividades incluyen, entre otras: energías renovables y alternativas, eficiencia energética, infraestructuras y tecnologías hidráulicas, control de la contaminación, tecnologías y tratamiento de residuos, servicios de apoyo medioambiental y alimentación sostenible.\n\n                Se gestiona de manera activa y, como tal, puede invertir en valores que no estén incluidos en el índice MSCI World Small Cap. El equipo de inversiones aplica asimismo la Política de inversión sostenible de BNP Paribas AM, que tiene en cuenta los criterios ambientales, sociales y de gobernanza (ASG) en las inversiones del fondo.\n                \n                El fondo reporta impacto positivo respecto a su benchmark en los ámbitos de emisión de carbono, generación de energías renovables, gestión del agua, y tratamiento de residuos.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/BNP-RV-Impact-KID_ES_es_LU0406803147_YES_2020-06-25.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0406803147_YES_2020-12-31.pdf',
                                category: 'Renta Variable Sector Ecológico',
                                asset: 'Renta Variable',
                                weight: '0.08',
                            },
                            weight: 0.03,
                            saving: 183.09090909090926,
                            input: 183.09090909090926,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU1892830081',
                                name: 'Fidelity Sustainable Water & Waste Fund',
                                alias: 'Fidelity Sustainable Water & Waste Fund',
                                description:
                                    'El fondo busca invertir en empresas dedicadas al diseño, fabricación o venta de productos y servicios relacionados con el sector de la gestión de aguas y residuos presentes en todo el mundo. La estrategia cuenta con un entorno propicio gracias a megatendencias globales como el crecimiento demográfico, las restricciones medioambientales, una normativa favorable, las tasas de urbanización y la creación de riqueza mundial. El fondo incluye criterios sociales, medioambientales y de buen gobierno (ASG) en sus procesos de inversión y gestión de riesgos. Es un fondo de gestión activa.\n\n                El fondo obtuvo la acreditación de inversión sostenible begla Towards Sustainability, y el sello LuxFlag ESG. LuxFLAG es una organización sin ánimo de lucro internacional e independiente cuyo objetivo es promover la inversión sostenible. Reconoce aquellos fondos que incorporan el análisis de criterios y consideraciones medioambientales, sociales y de gobernanzas (criterios ESG por sus siglas en inglés – Environmental, Social, and Governance) en su proceso de inversión',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/Franklin-WW-KID_ES_es_LU1892830081_YES_2020-05-22.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_en_LU1892830081_YES_2020-12-31.pdf',
                                category: 'Renta Variable Sector Água & Residuos',
                                asset: 'Renta Variable',
                                weight: '0.06',
                            },
                            weight: 0.03,
                            saving: 137.31818181818193,
                            input: 137.31818181818193,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'IE00B0V9TC00',
                                name: 'PIMCO GIS Global High Yield Bond Fund',
                                alias: 'PIMCO Global High Yield Bond',
                                description:
                                    'El fondo trata de alcanzar su objetivo adquiriendo exposición a una gama diversificada de valores e instrumentos de renta fija de alta rentabilidad emitidos por empresas de todo el mundo. El fondo invertirá principalmente en valores «sin grado de inversión». Generalmente, se considera que estas inversiones son más arriesgadas que los valores con «grado de inversión», pero pueden generar ingresos más elevados. Es un fondo de gestión activa.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/09/KID_ES_es_IE00B2R34Y72_YES_2020-06-24.pdf',
                                monthly_report: null,
                                category: 'Renta Fija Corporativa Alto Rendimiento H I',
                                asset: 'Renta Fija',
                                weight: '0.14',
                            },
                            weight: 0.04,
                            saving: 191.0649999999999,
                            input: 191.0649999999999,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU0097116601',
                                name: 'Eurizon Treasury USD',
                                alias: 'Eurizon Fund - Bond USD Short Term',
                                description:
                                    'Este fondo invierte en bonos gubernamentales de corto plazo que están denominados en dólares estadounidenses. Es un fondo de gestión activa.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/06/KID_ES_es_LU0335989397_YES_2020-02-18.pdf',
                                monthly_report: null,
                                category: 'Renta Fija USA Corto Plazo',
                                asset: 'Renta Fija',
                                weight: '0.05',
                            },
                            weight: 0.01,
                            saving: 68.23749999999997,
                            input: 68.23749999999997,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'IE00B67T5G21',
                                name: 'iShares Euro Inv Grd Corp Bd Idx Fd (IE)',
                                alias: 'iShares Euro Investment Grade Corporate Bond Index Fund (IE) Inst EUR',
                                description:
                                    'Este fondo invierte principalmente en bonos corporativos en euros con grado de inversión (Investment Grade) y con vencimientos de medio y largo plazo. Es un fondo indexado (gestión pasiva).',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/06/KID_ES_es_IE00B67T5G21_YES_2020-02-12.pdf',
                                monthly_report: null,
                                category: 'Renta Fija Corporativa Grado de Inversión',
                                asset: 'Renta Fija',
                                weight: '0.10',
                            },
                            weight: 0.03,
                            saving: 136.47499999999994,
                            input: 136.47499999999994,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU0935221761',
                                name: 'Ostrum Euro Short Term Credit',
                                alias: 'OSTRUM Euro Short Term Credit',
                                description:
                                    'Este fondo invierte principalmente en bonos corporativos en euros con grado de inversión (Investment Grade) y con vencimientos de entre uno y tres años. Es un fondo de gestión activa.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2019/10/KID_ES_es_LU1118012159_YES_2020-02-19-OSTRUM.pdf',
                                monthly_report: null,
                                category: 'Renta Fija Corp. Euro IG Corto Plazo',
                                asset: 'Renta Fija',
                                weight: '0.11',
                            },
                            weight: 0.03,
                            saving: 150.12249999999992,
                            input: 150.12249999999992,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'IE00B03HD191',
                                name: 'VANGUARD GLOBAL STOCK INDEX',
                                alias: 'Vanguard Global Stock Index',
                                description:
                                    'El objetivo del Fondo es replicar el rendimiento del índice MSCI World Index, para obtener el retorno total de los mercados de renta variable de los mercados desarrollados. El Índice está formado por acciones de medianas y grandes empresas. Es un fondo indexado (gestión pasiva).',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE00B03HD191_YES_2020-02-18-VGD-Global.pdf',
                                monthly_report: null,
                                category: 'Renta Variable mercados desarrollados',
                                asset: 'Renta Variable',
                                weight: '0.33',
                            },
                            weight: 0.34,
                            saving: 1801.4699999999993,
                            input: 1801.4699999999993,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'IE0031786696',
                                name: 'VANGUARD EM MKTS STK INDX',
                                alias: 'Vanguard Emerging Markets Stock Index',
                                description:
                                    'El Fondo pretende replicar la rentabilidad del índice MSCI Emerging Markets Index. El Indice está formado por acciones de empresas de gran y mediana capitalización de mercados emergentes. Es un fondo indexado (gestión pasiva).',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE0031786696_YES_2020-02-18-VGD-EM.pdf',
                                monthly_report: null,
                                category: 'Renta Variable mercados emergentes',
                                asset: 'Renta Variable',
                                weight: '0.12',
                            },
                            weight: 0.12,
                            saving: 655.0799999999997,
                            input: 655.0799999999997,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'IE00B42W4L06',
                                name: 'VANGUARD GB SM CAP IDX',
                                alias: 'Vanguard Global Small-Cap Index',
                                description:
                                    'El Fondo pretende replicar la rentabilidad del índice MSCI World Small Cap. El Indice está formado por acciones de empresas de pequeña y mediana capitalización de los países desarrollados. Es un fondo indexado (gestión pasiva).',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE00B42W4L06_YES_2020-02-18-VGD-SC.pdf',
                                monthly_report: null,
                                category: 'Renta Variable Small Caps',
                                asset: 'Renta Variable',
                                weight: '0.05',
                            },
                            weight: 0.05,
                            saving: 272.94999999999993,
                            input: 272.94999999999993,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU1670632170',
                                name: 'M&G (Lux) Em Mkts Bd C H EUR Acc',
                                alias: 'M&G Emerging Markets Bond H',
                                description:
                                    'El objetivo principal del fondo es obtener ingresos de una cartera de obligaciones  y otros valores de deuda de mercados emergentes de todo el mundo. Es un fondo de gestión activa.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_LU1670632170_YES_2020-02-12-MG.pdf',
                                monthly_report: null,
                                category: 'Renta Fija Mercados Emergentes H C',
                                asset: 'Renta Fija',
                                weight: '0.10',
                            },
                            weight: 0.03,
                            saving: 136.47499999999994,
                            input: 136.47499999999994,
                            output: 0,
                        },
                    ],
                },
            ],
        },
        {
            objective: 7,
            services: [
                {
                    financial_service: {
                        type: 'mutual',
                        annual_percentage_rate: '0.0060',
                        liquidity: 'En cualquier momento',
                        taxation_when_selling: 'Base imponible del ahorro IRPF',
                        tax_advantage: 'Traspaso sin coste fiscal',
                    },
                    financial_products: [
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU1620157294',
                                name: 'BNP Paribas Funds Green Bond',
                                alias: 'BNP Paribas Green Bond',
                                description:
                                    'El fondo trata de incrementar el valor de sus activos a medio plazo invirtiendo en bonos verdes globales (Europa principalmente) que apoyen proyectos medioambientales, emitidos por organismos soberanos supranacionales y corporativos, entidades locales y/o gobiernos en divisas fuertes.\n\n                Se gestiona de manera activa y, como tal, puede invertir en valores que no estén incluidos en el índice Bloomberg Barclays MSCI Global Green Bond Index. El equipo de inversiones aplica asimismo la política de inversión sostenible de BNP Paribas AM, que tiene en cuenta los criterios ambientales, sociales y de gobernanza (ASG) en las inversiones del fondo.  El proceso de inversión filtra y excluye los bonos verdes con peor puntuación ASG, y procura evitar «green-washing» a través de una definición exigente del bono verde.\n                \n                El fondo obtuvo la acreditación ISR (inversión socialmente responsable) por parte del Ministerio de finanzas francés, y el sello GreenFin por parte del Ministerio francés de la Transición Ecológica y Solidária.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/BNP-Green-Bond-KID_ES_es_LU1620157294_YES_2020-02-19.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU1620157294_YES_2020-12-31.pdf',
                                category: 'Renta Fija Diversificada',
                                asset: 'Renta Fija',
                                weight: '0.09',
                            },
                            weight: 0.09,
                            saving: 234.24485294117667,
                            input: 234.24485294117667,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'IE00BD0DT792',
                                name: 'iShares Green Bond Index Fund',
                                alias: 'iShares Green Bond Index Fund',
                                description:
                                    'El fondo trata de obtener rentabilidad de su inversión mediante una combinación de revalorización del capital y rendimientos que refleje la rentabilidad del Bloomberg Barclays MSCI Green Bond Index, el índice de referencia del fondo (el Índice). El Fondo invierte principalmente en los valores de renta fija (RF) (como bonos) que componen el Índice, cuyos ingresos se utilizan para financiar proyectos con beneficios medioambientales directos como pueden ser por ejemplo la reducción de emisiones de CO2, el ahorro y tratamiento del agua, la preservación forestal, o la generación de energías renovables.\n\n                Es un fondo indexado (gestión pasiva).',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/iShares-Green-Bonds-KID_ES_es_IE00BD0DT792_YES_2020-02-12.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_IE00BD0DT792_YES_2020-11-30-1.pdf',
                                category: 'Renta Fija Diversificada',
                                asset: 'Renta Fija',
                                weight: '0.05',
                            },
                            weight: 0.05,
                            saving: 130.13602941176487,
                            input: 130.13602941176487,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU0966596875',
                                name: 'DPAM Bonds Emerging Markets Sustainable',
                                alias: 'DPAM Bonds Emerging Markets Sustainable',
                                description:
                                    'El fondo invierte principalmente en obligaciones y/u otros títulos de deuda denominados en cualquier divisa, emitidos (o garantizados) por países emergentes (lo que incluye sus administraciones públicas territoriales y sus organismos públicos —o asimilados—) o por organismos públicos internacionales (como el Banco Mundial y el Banco Europeo de Reconstrucción y Desarrollo) y seleccionados según criterios vinculados al desarrollo sostenible (como, por ejemplo, la igualdad social, el respeto por el medio ambiente y una gobernanza política y económica equitativa).\n\n                Se trata de un fondo gestionado activamente.\n                \n                El fondo obtuvo el sello de inversión sostenible belga Towards Sustainability y la acreditación LuxFLAG ESG. LuxFLAG es una organización sin ánimo de lucro internacional e independiente cuyo objetivo es promover la inversión sostenible. Reconoce aquellos fondos que incorporan el análisis de criterios y consideraciones medioambientales, sociales y de gobernanzas (ESG) en su proceso de inversión.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/DPAM-RF-EM-KID_ES_en_LU0966596875_YES_2020-02-19.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0966596875_YES_2020-11-30.pdf',
                                category: 'Renta Fija Países Emergentes',
                                asset: 'Renta Fija',
                                weight: '0.08',
                            },
                            weight: 0.08,
                            saving: 208.21764705882373,
                            input: 208.21764705882373,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU1644441476',
                                name: 'Candriam SRI Bond Global High Yield',
                                alias: 'Candriam SRI Bond Global High Yield',
                                description:
                                    'Este fondo de renta fija invierte en bonos de alto rendimiento («High Yield») de empresas internacionales. Los valores se seleccionan en función de un proceso de análisis económico-financiero, así como de criterios medioambientales, sociales y de gobierno corporativo (ASG). El análisis ASG combina un enfoque «Best-in-Class» con un proceso de exclusión de los emisores que incumplan con los principios del pacto mundial de las Naciones Unidas en materia de derechos humanos y laborales, medio-ambiente y lucha contra la corrupción y/o que estén expuestos a actividades controvertidas (armamento, carbón térmico, tabaco etc…). El fondo reporta un impacto positivo en los siguientes ámbitos (respecto a su benchmark): reducción de consumo de agua y de emisión de carbono, producción de energías renovables, programas de biodiversidad, y disminución de la generación de residuos.\n\n                El fondo obtuvo el sello ISR (inversión socialmente responsable) por parte del Ministerio de finanzas francés y el sello Towards Sustainability por parte del gobierno belga.\n                \n                Es un fondo de gestión activa.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/Candriam-HY-KID_ES_es_LU1644441476_YES_2020-07-21.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/CandriamSustainableBondGlobalHighYield_Rep_R_R_20201231-1.pdf',
                                category: 'Renta Fija Alto Rendimiento',
                                asset: 'Renta Fija',
                                weight: '0.12',
                            },
                            weight: 0.12,
                            saving: 312.3264705882356,
                            input: 312.3264705882356,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU1434524259',
                                name: 'Candriam SRI Equity Emerging Markets',
                                alias: 'Candriam SRI Equity Emerging Markets',
                                description:
                                    'El fondo invierte en acciones de empresas de países emergentes que cumplen con los diez principios del pacto mundial de la ONU y que no están involucradas en actividades controvertidas como por ejemplo el comercio de armas. El fondo excluye de sus inversiones las empresas que no cumplan con los mínimos criterios ASG, y enfoca sus inversiones en empresas que están bien posicionadas para afrontar los retos de la sostenibilidad global.\n\n                Los impactos ASG más significativos reportados por el fondo se concentra en los ahorros de emisión de carbono y de consumo de agua.\n                \n                Es un fondo de gestión activa.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/Candriam-RV-EM-KID_ES_es_LU1434524259_YES_2020-07-06.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/CandriamSustainableEquityEmergingMarkets_Rep_R_R_20201231.pdf',
                                category: 'Renta Variable Países Emergentes',
                                asset: 'Renta Variable',
                                weight: '0.20',
                            },
                            weight: 0.01,
                            saving: 23.318181818181838,
                            input: 23.318181818181838,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU1437020909',
                                name: 'Amundi Index MSCI Global Climate Change',
                                alias: 'Amundi Index MSCI Global Climate Change',
                                description:
                                    'El fondo pretende replicar la rentabilidad del Índice MSCI World Climate Change Index. Este índice está basado en el MSCI World Index («Índice base»), que incluye valores de gran y mediana capitalización de 23 países considerados mercados desarrollados. El objetivo del Índice consiste en representar la rentabilidad de una estrategia en la que se re-ponderan los valores en función de las oportunidades y los riesgos asociados a la transición hacia un modelo económico de menor consumo de carbono, y se procura reducir al mínimo las exclusiones del Índice base.\n\n                Es un fondo indexado (gestión pasiva).',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/Amundi-RV-Climate-Change-MR_ES_es_LU1437020909_RES_2020-07-31.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU1437020909_RES_2020-12-31.pdf',
                                category: 'Renta Variable Global',
                                asset: 'Renta Variable',
                                weight: '0.12',
                            },
                            weight: 0.01,
                            saving: 13.990909090909101,
                            input: 13.990909090909101,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU0348927095',
                                name: 'Nordea Global Climate & Environment Fund',
                                alias: 'Nordea Global Climate & Environment Fund',
                                description:
                                    'El objetivo del fondo consiste en proporcionar a los accionistas un crecimiento de la inversión a largo plazo. Al gestionar activamente la cartera del fondo, el equipo de gestión se centra en aquellas empresas que desarrollan soluciones respetuosas con el medioambiente y el clima, como las energías renovables y la eficiencia de los recursos, y que parecen ofrecer mejores perspectivas de crecimiento y características de inversión. El fondo invierte principalmente en títulos de renta variable de empresas de todo el mundo. El fondo también sigue una política de exclusión de empresas de algunos sectores controvertidos cómo pueden ser el carbón térmico y metalúrgico, el petróleo y gas convencionales y no convencionales, el armamento militar y nuclear, las armas convencionales y controvertidas, y el tabaco.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/Nordea-KID_ES_es_LU0348927095_YES_2020-06-25.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0348927095_YES_2020-12-31.pdf',
                                category: 'Renta Variable Sector Ecológico',
                                asset: 'Renta Variable',
                                weight: '0.12',
                            },
                            weight: 0.005181818181818185,
                            saving: 13.990909090909101,
                            input: 13.990909090909101,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU0503631631',
                                name: 'Pictet Global Environmental Opportunities',
                                alias: 'Pictet Global Environmental Opportunities',
                                description:
                                    'El fondo invierte a nivel global y principalmente en acciones de empresas de energía limpia y agua, agricultura, silvicultura y otras áreas de la cadena de valor medioambiental. Es un fondo de gestión activa.\n\n                El fondo obtuvo el sello de inversión sostenible belga Towards Sustainability, la acreditación de ASG francesa Label ISR, y el FNG Label. Forum Nachhaltige Geldanlagen (FNG-Siegel), fundada en 2001, es la asociación de la industria que promueve la inversión sostenible en Alemania, Austria y Suiza. El objetivo de FNG es mejorar los estándares de calidad para productos de inversión sostenibles, para asegurar el cumplimiento de las normas mínimas internacionalmente reconocidas.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure: 'https://www.finanbest.com/wp-content/uploads/2020/08/Pictet-KID.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0503631631_YES_2020-12-31.pdf',
                                category: 'Renta Variable Sector Ecológico',
                                asset: 'Renta Variable',
                                weight: '0.08',
                            },
                            weight: 0.0034545454545454575,
                            saving: 9.327272727272735,
                            input: 9.327272727272735,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU0406803147',
                                name: 'BNP Paribas Funds Climate Impact',
                                alias: 'BNP Paribas Funds Climate Impact',
                                description:
                                    'El fondo trata de incrementar el valor de sus activos a medio plazo invirtiendo en acciones emitidas por empresas que operen centradas en permitir la adaptación al cambio climático o la mitigación de sus efectos (principalmente empresas europeas y estadounidenses de pequeña capitalización). Estas actividades incluyen, entre otras: energías renovables y alternativas, eficiencia energética, infraestructuras y tecnologías hidráulicas, control de la contaminación, tecnologías y tratamiento de residuos, servicios de apoyo medioambiental y alimentación sostenible.\n\n                Se gestiona de manera activa y, como tal, puede invertir en valores que no estén incluidos en el índice MSCI World Small Cap. El equipo de inversiones aplica asimismo la Política de inversión sostenible de BNP Paribas AM, que tiene en cuenta los criterios ambientales, sociales y de gobernanza (ASG) en las inversiones del fondo.\n                \n                El fondo reporta impacto positivo respecto a su benchmark en los ámbitos de emisión de carbono, generación de energías renovables, gestión del agua, y tratamiento de residuos.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/BNP-RV-Impact-KID_ES_es_LU0406803147_YES_2020-06-25.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0406803147_YES_2020-12-31.pdf',
                                category: 'Renta Variable Sector Ecológico',
                                asset: 'Renta Variable',
                                weight: '0.08',
                            },
                            weight: 0.0034545454545454575,
                            saving: 9.327272727272735,
                            input: 9.327272727272735,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU1892830081',
                                name: 'Fidelity Sustainable Water & Waste Fund',
                                alias: 'Fidelity Sustainable Water & Waste Fund',
                                description:
                                    'El fondo busca invertir en empresas dedicadas al diseño, fabricación o venta de productos y servicios relacionados con el sector de la gestión de aguas y residuos presentes en todo el mundo. La estrategia cuenta con un entorno propicio gracias a megatendencias globales como el crecimiento demográfico, las restricciones medioambientales, una normativa favorable, las tasas de urbanización y la creación de riqueza mundial. El fondo incluye criterios sociales, medioambientales y de buen gobierno (ASG) en sus procesos de inversión y gestión de riesgos. Es un fondo de gestión activa.\n\n                El fondo obtuvo la acreditación de inversión sostenible begla Towards Sustainability, y el sello LuxFlag ESG. LuxFLAG es una organización sin ánimo de lucro internacional e independiente cuyo objetivo es promover la inversión sostenible. Reconoce aquellos fondos que incorporan el análisis de criterios y consideraciones medioambientales, sociales y de gobernanzas (criterios ESG por sus siglas en inglés – Environmental, Social, and Governance) en su proceso de inversión',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/Franklin-WW-KID_ES_es_LU1892830081_YES_2020-05-22.pdf',
                                monthly_report:
                                    'https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_en_LU1892830081_YES_2020-12-31.pdf',
                                category: 'Renta Variable Sector Água & Residuos',
                                asset: 'Renta Variable',
                                weight: '0.06',
                            },
                            weight: 0.0025909090909090925,
                            saving: 6.9954545454545505,
                            input: 6.9954545454545505,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'IE00B0V9TC00',
                                name: 'PIMCO GIS Global High Yield Bond Fund',
                                alias: 'PIMCO Global High Yield Bond',
                                description:
                                    'El fondo trata de alcanzar su objetivo adquiriendo exposición a una gama diversificada de valores e instrumentos de renta fija de alta rentabilidad emitidos por empresas de todo el mundo. El fondo invertirá principalmente en valores «sin grado de inversión». Generalmente, se considera que estas inversiones son más arriesgadas que los valores con «grado de inversión», pero pueden generar ingresos más elevados. Es un fondo de gestión activa.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/09/KID_ES_es_IE00B2R34Y72_YES_2020-06-24.pdf',
                                monthly_report: null,
                                category: 'Renta Fija Corporativa Alto Rendimiento H I',
                                asset: 'Renta Fija',
                                weight: '0.14',
                            },
                            weight: 0.17,
                            saving: 447.74099999999993,
                            input: 447.74099999999993,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU0097116601',
                                name: 'Eurizon Treasury USD',
                                alias: 'Eurizon Fund - Bond USD Short Term',
                                description:
                                    'Este fondo invierte en bonos gubernamentales de corto plazo que están denominados en dólares estadounidenses. Es un fondo de gestión activa.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/06/KID_ES_es_LU0335989397_YES_2020-02-18.pdf',
                                monthly_report: null,
                                category: 'Renta Fija USA Corto Plazo',
                                asset: 'Renta Fija',
                                weight: '0.05',
                            },
                            weight: 0.06,
                            saving: 159.90749999999997,
                            input: 159.90749999999997,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'IE00B67T5G21',
                                name: 'iShares Euro Inv Grd Corp Bd Idx Fd (IE)',
                                alias: 'iShares Euro Investment Grade Corporate Bond Index Fund (IE) Inst EUR',
                                description:
                                    'Este fondo invierte principalmente en bonos corporativos en euros con grado de inversión (Investment Grade) y con vencimientos de medio y largo plazo. Es un fondo indexado (gestión pasiva).',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2020/06/KID_ES_es_IE00B67T5G21_YES_2020-02-12.pdf',
                                monthly_report: null,
                                category: 'Renta Fija Corporativa Grado de Inversión',
                                asset: 'Renta Fija',
                                weight: '0.10',
                            },
                            weight: 0.12,
                            saving: 319.81499999999994,
                            input: 319.81499999999994,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU0935221761',
                                name: 'Ostrum Euro Short Term Credit',
                                alias: 'OSTRUM Euro Short Term Credit',
                                description:
                                    'Este fondo invierte principalmente en bonos corporativos en euros con grado de inversión (Investment Grade) y con vencimientos de entre uno y tres años. Es un fondo de gestión activa.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2019/10/KID_ES_es_LU1118012159_YES_2020-02-19-OSTRUM.pdf',
                                monthly_report: null,
                                category: 'Renta Fija Corp. Euro IG Corto Plazo',
                                asset: 'Renta Fija',
                                weight: '0.11',
                            },
                            weight: 0.13,
                            saving: 351.79649999999987,
                            input: 351.79649999999987,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'IE00B03HD191',
                                name: 'VANGUARD GLOBAL STOCK INDEX',
                                alias: 'Vanguard Global Stock Index',
                                description:
                                    'El objetivo del Fondo es replicar el rendimiento del índice MSCI World Index, para obtener el retorno total de los mercados de renta variable de los mercados desarrollados. El Índice está formado por acciones de medianas y grandes empresas. Es un fondo indexado (gestión pasiva).',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE00B03HD191_YES_2020-02-18-VGD-Global.pdf',
                                monthly_report: null,
                                category: 'Renta Variable mercados desarrollados',
                                asset: 'Renta Variable',
                                weight: '0.33',
                            },
                            weight: 0.03,
                            saving: 91.77299999999998,
                            input: 91.77299999999998,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'IE0031786696',
                                name: 'VANGUARD EM MKTS STK INDX',
                                alias: 'Vanguard Emerging Markets Stock Index',
                                description:
                                    'El Fondo pretende replicar la rentabilidad del índice MSCI Emerging Markets Index. El Indice está formado por acciones de empresas de gran y mediana capitalización de mercados emergentes. Es un fondo indexado (gestión pasiva).',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE0031786696_YES_2020-02-18-VGD-EM.pdf',
                                monthly_report: null,
                                category: 'Renta Variable mercados emergentes',
                                asset: 'Renta Variable',
                                weight: '0.12',
                            },
                            weight: 0.01,
                            saving: 33.371999999999986,
                            input: 33.371999999999986,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'IE00B42W4L06',
                                name: 'VANGUARD GB SM CAP IDX',
                                alias: 'Vanguard Global Small-Cap Index',
                                description:
                                    'El Fondo pretende replicar la rentabilidad del índice MSCI World Small Cap. El Indice está formado por acciones de empresas de pequeña y mediana capitalización de los países desarrollados. Es un fondo indexado (gestión pasiva).',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 1,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE00B42W4L06_YES_2020-02-18-VGD-SC.pdf',
                                monthly_report: null,
                                category: 'Renta Variable Small Caps',
                                asset: 'Renta Variable',
                                weight: '0.05',
                            },
                            weight: 0.0051499999999999975,
                            saving: 13.904999999999994,
                            input: 13.904999999999994,
                            output: 0,
                        },
                        {
                            financial_product: {
                                _type: 'InvestmentFund',
                                provider: null,
                                identifier: 'LU1670632170',
                                name: 'M&G (Lux) Em Mkts Bd C H EUR Acc',
                                alias: 'M&G Emerging Markets Bond H',
                                description:
                                    'El objetivo principal del fondo es obtener ingresos de una cartera de obligaciones  y otros valores de deuda de mercados emergentes de todo el mundo. Es un fondo de gestión activa.',
                                currency: 'EUR',
                                annual_percentage_rate: null,
                                fees_and_comissions_specification: null,
                                interest_rate: null,
                                max_equity_exposure: 0,
                                brochure:
                                    'https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_LU1670632170_YES_2020-02-12-MG.pdf',
                                monthly_report: null,
                                category: 'Renta Fija Mercados Emergentes H C',
                                asset: 'Renta Fija',
                                weight: '0.10',
                            },
                            weight: 0.12,
                            saving: 319.81499999999994,
                            input: 319.81499999999994,
                            output: 0,
                        },
                    ],
                },
            ],
        },
    ],
    inputChart: [
        {
            id: 9,
            data: [
                {
                    name: 1,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2022-05-02',
                    end_date: '2023-04-02',
                },
                {
                    name: 2,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2023-05-02',
                    end_date: '2024-04-02',
                },
                {
                    name: 3,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2024-05-02',
                    end_date: '2025-04-02',
                },
                {
                    name: 4,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2025-05-02',
                    end_date: '2026-04-02',
                },
                {
                    name: 5,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2026-05-02',
                    end_date: '2027-04-02',
                },
                {
                    name: 6,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2027-05-02',
                    end_date: '2028-04-02',
                },
                {
                    name: 7,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2028-05-02',
                    end_date: '2029-04-02',
                },
                {
                    name: 8,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2029-05-02',
                    end_date: '2030-04-02',
                },
                {
                    name: 9,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2030-05-02',
                    end_date: '2031-04-02',
                },
                {
                    name: 10,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2031-05-02',
                    end_date: '2032-04-02',
                },
                {
                    name: 11,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2032-05-02',
                    end_date: '2033-04-02',
                },
                {
                    name: 12,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2033-05-02',
                    end_date: '2034-04-02',
                },
                {
                    name: 13,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2034-05-02',
                    end_date: '2035-04-02',
                },
                {
                    name: 14,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2035-05-02',
                    end_date: '2036-04-02',
                },
                {
                    name: 15,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2036-05-02',
                    end_date: '2037-04-02',
                },
                {
                    name: 16,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2037-05-02',
                    end_date: '2038-04-02',
                },
                {
                    name: 17,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2038-05-02',
                    end_date: '2039-04-02',
                },
                {
                    name: 18,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2039-05-02',
                    end_date: '2040-04-02',
                },
                {
                    name: 19,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2040-05-02',
                    end_date: '2041-04-02',
                },
                {
                    name: 20,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2041-05-02',
                    end_date: '2042-04-02',
                },
                {
                    name: 21,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2042-05-02',
                    end_date: '2043-04-02',
                },
                {
                    name: 22,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2043-05-02',
                    end_date: '2044-04-02',
                },
                {
                    name: 23,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2044-05-02',
                    end_date: '2045-04-02',
                },
                {
                    name: 24,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2045-05-02',
                    end_date: '2046-04-02',
                },
                {
                    name: 25,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2046-05-02',
                    end_date: '2047-04-02',
                },
                {
                    name: 26,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2047-05-02',
                    end_date: '2048-04-02',
                },
                {
                    name: 27,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2048-05-02',
                    end_date: '2049-04-02',
                },
                {
                    name: 28,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2049-05-02',
                    end_date: '2050-04-02',
                },
                {
                    name: 29,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2050-05-02',
                    end_date: '2051-04-02',
                },
                {
                    name: 30,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2051-05-02',
                    end_date: '2052-04-02',
                },
                {
                    name: 31,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2052-05-02',
                    end_date: '2053-04-02',
                },
                {
                    name: 32,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2053-05-02',
                    end_date: '2054-04-02',
                },
                {
                    name: 33,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2054-05-02',
                    end_date: '2055-04-02',
                },
                {
                    name: 34,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2055-05-02',
                    end_date: '2056-04-02',
                },
                {
                    name: 35,
                    input: 325.02366955945894,
                    monthly_input: 27.08530579662157,
                    init_date: '2056-05-02',
                    end_date: '2057-04-02',
                },
                {
                    name: 36,
                    input: 162.51183477972944,
                    monthly_input: 27.08530579662157,
                    init_date: '2057-05-02',
                    end_date: '2057-10-02',
                },
            ],
        },
        {
            id: 7,
            data: [
                {
                    name: 1,
                    input: 11509.1636482411,
                    monthly_input: 40,
                    init_date: '2022-05-02',
                    end_date: '2023-04-02',
                },
            ],
        },
    ],
    outputChart: [
        {
            id: 9,
            data: {
                '2022': {
                    name: 2022,
                    output: 0,
                },
                '2023': {
                    name: 2023,
                    output: 0,
                },
                '2024': {
                    name: 2024,
                    output: 0,
                },
                '2025': {
                    name: 2025,
                    output: 0,
                },
                '2026': {
                    name: 2026,
                    output: 0,
                },
                '2027': {
                    name: 2027,
                    output: 0,
                },
                '2028': {
                    name: 2028,
                    output: 0,
                },
                '2029': {
                    name: 2029,
                    output: 0,
                },
                '2030': {
                    name: 2030,
                    output: 0,
                },
                '2031': {
                    name: 2031,
                    output: 0,
                },
                '2032': {
                    name: 2032,
                    output: 0,
                },
                '2033': {
                    name: 2033,
                    output: 0,
                },
                '2034': {
                    name: 2034,
                    output: 0,
                },
                '2035': {
                    name: 2035,
                    output: 0,
                },
                '2036': {
                    name: 2036,
                    output: 0,
                },
                '2037': {
                    name: 2037,
                    output: 0,
                },
                '2038': {
                    name: 2038,
                    output: 0,
                },
                '2039': {
                    name: 2039,
                    output: 0,
                },
                '2040': {
                    name: 2040,
                    output: 0,
                },
                '2041': {
                    name: 2041,
                    output: 0,
                },
                '2042': {
                    name: 2042,
                    output: 0,
                },
                '2043': {
                    name: 2043,
                    output: 0,
                },
                '2044': {
                    name: 2044,
                    output: 0,
                },
                '2045': {
                    name: 2045,
                    output: 0,
                },
                '2046': {
                    name: 2046,
                    output: 0,
                },
                '2047': {
                    name: 2047,
                    output: 0,
                },
                '2048': {
                    name: 2048,
                    output: 0,
                },
                '2049': {
                    name: 2049,
                    output: 0,
                },
                '2050': {
                    name: 2050,
                    output: 0,
                },
                '2051': {
                    name: 2051,
                    output: 0,
                },
                '2052': {
                    name: 2052,
                    output: 0,
                },
                '2053': {
                    name: 2053,
                    output: 0,
                },
                '2054': {
                    name: 2054,
                    output: 0,
                },
                '2055': {
                    name: 2055,
                    output: 0,
                },
                '2056': {
                    name: 2056,
                    output: 0,
                },
                '2057': {
                    name: 2057,
                    output: 280.8714038962523,
                },
                '2058': {
                    name: 2058,
                    output: 1702.0807076112883,
                },
                '2059': {
                    name: 2059,
                    output: 1727.6119182254577,
                },
                '2060': {
                    name: 2060,
                    output: 1753.5260969988394,
                },
                '2061': {
                    name: 2061,
                    output: 1779.8289884538226,
                },
                '2062': {
                    name: 2062,
                    output: 1806.526423280629,
                },
                '2063': {
                    name: 2063,
                    output: 1833.6243196298378,
                },
                '2064': {
                    name: 2064,
                    output: 1861.1286844242863,
                },
                '2065': {
                    name: 2065,
                    output: 1889.0456146906504,
                },
                '2066': {
                    name: 2066,
                    output: 1917.3812989110093,
                },
                '2067': {
                    name: 2067,
                    output: 1946.1420183946743,
                },
                '2068': {
                    name: 2068,
                    output: 1975.334148670595,
                },
                '2069': {
                    name: 2069,
                    output: 2004.9641609006528,
                },
                '2070': {
                    name: 2070,
                    output: 2035.0386233141628,
                },
                '2071': {
                    name: 2071,
                    output: 2065.5642026638748,
                },
                '2072': {
                    name: 2072,
                    output: 2096.547665703833,
                },
                '2073': {
                    name: 2073,
                    output: 2127.9958806893906,
                },
                '2074': {
                    name: 2074,
                    output: 2159.915818899731,
                },
                '2075': {
                    name: 2075,
                    output: 2192.314556183226,
                },
                '2076': {
                    name: 2076,
                    output: 2225.199274525975,
                },
                '2077': {
                    name: 2077,
                    output: 2258.577263643864,
                },
                '2078': {
                    name: 2078,
                    output: 2292.4559225985217,
                },
                '2079': {
                    name: 2079,
                    output: 2326.8427614374996,
                },
                '2080': {
                    name: 2080,
                    output: 2361.745402859062,
                },
                '2081': {
                    name: 2081,
                    output: 2397.171583901948,
                },
                '2082': {
                    name: 2082,
                    output: 2229.3646283679527,
                },
            },
        },
        {
            id: 7,
            data: {
                '2022': {
                    name: 2022,
                    output: 0,
                },
                '2023': {
                    name: 2023,
                    output: 3382.995,
                },
                '2024': {
                    name: 2024,
                    output: 3433.739924999999,
                },
                '2025': {
                    name: 2025,
                    output: 3485.2460238749986,
                },
                '2026': {
                    name: 2026,
                    output: 3537.5247142331236,
                },
                '2027': {
                    name: 2027,
                    output: 3590.58758494662,
                },
            },
        },
    ],
    cumulativeChart: [
        {
            id: 1,
            data: [
                {
                    name: 1,
                    input: 4625.0236695594585,
                    performance: 158.4192059134976,
                    init_date: '2022-05-02',
                    end_date: '2023-04-02',
                },
                {
                    name: 2,
                    input: 4950.047339118917,
                    performance: 333.9522896187375,
                    init_date: '2023-05-02',
                    end_date: '2024-04-02',
                },
                {
                    name: 3,
                    input: 5275.071008678376,
                    performance: 527.2050823895488,
                    init_date: '2024-05-02',
                    end_date: '2025-04-02',
                },
                {
                    name: 4,
                    input: 5600.094678237834,
                    performance: 738.8048619268504,
                    init_date: '2025-05-02',
                    end_date: '2026-04-02',
                },
                {
                    name: 5,
                    input: 5925.118347797293,
                    performance: 969.4011115621788,
                    init_date: '2026-05-02',
                    end_date: '2027-04-02',
                },
                {
                    name: 6,
                    input: 6250.142017356751,
                    performance: 1219.666306337002,
                    init_date: '2027-05-02',
                    end_date: '2028-04-02',
                },
                {
                    name: 7,
                    input: 6575.16568691621,
                    performance: 1490.2967269092592,
                    init_date: '2028-05-02',
                    end_date: '2029-04-02',
                },
                {
                    name: 8,
                    input: 6900.189356475668,
                    performance: 1782.0133022721786,
                    init_date: '2029-05-02',
                    end_date: '2030-04-02',
                },
                {
                    name: 9,
                    input: 7225.213026035127,
                    performance: 2095.5624823053486,
                    init_date: '2030-05-02',
                    end_date: '2031-04-02',
                },
                {
                    name: 10,
                    input: 7550.236695594585,
                    performance: 2431.7171412140974,
                    init_date: '2031-05-02',
                    end_date: '2032-04-02',
                },
                {
                    name: 11,
                    input: 7875.260365154044,
                    performance: 2791.2775129506217,
                    init_date: '2032-05-02',
                    end_date: '2033-04-02',
                },
                {
                    name: 12,
                    input: 8200.284034713502,
                    performance: 3175.072159749026,
                    init_date: '2033-05-02',
                    end_date: '2034-04-02',
                },
                {
                    name: 13,
                    input: 8525.307704272962,
                    performance: 3583.9589749464967,
                    init_date: '2034-05-02',
                    end_date: '2035-04-02',
                },
                {
                    name: 14,
                    input: 8850.331373832421,
                    performance: 4018.826221304363,
                    init_date: '2035-05-02',
                    end_date: '2036-04-02',
                },
                {
                    name: 15,
                    input: 9175.35504339188,
                    performance: 4480.5936060856975,
                    init_date: '2036-05-02',
                    end_date: '2037-04-02',
                },
                {
                    name: 16,
                    input: 9500.37871295134,
                    performance: 4970.2133941907,
                    init_date: '2037-05-02',
                    end_date: '2038-04-02',
                },
                {
                    name: 17,
                    input: 9825.4023825108,
                    performance: 5488.671560697025,
                    init_date: '2038-05-02',
                    end_date: '2039-04-02',
                },
                {
                    name: 18,
                    input: 10150.42605207026,
                    performance: 6036.988984200077,
                    init_date: '2039-05-02',
                    end_date: '2040-04-02',
                },
                {
                    name: 19,
                    input: 10475.449721629719,
                    performance: 6616.222682397543,
                    init_date: '2040-05-02',
                    end_date: '2041-04-02',
                },
                {
                    name: 20,
                    input: 10800.473391189178,
                    performance: 7227.467091413611,
                    init_date: '2041-05-02',
                    end_date: '2042-04-02',
                },
                {
                    name: 21,
                    input: 11125.497060748638,
                    performance: 7871.855390411243,
                    init_date: '2042-05-02',
                    end_date: '2043-04-02',
                },
                {
                    name: 22,
                    input: 11450.520730308097,
                    performance: 8550.560873095796,
                    init_date: '2043-05-02',
                    end_date: '2044-04-02',
                },
                {
                    name: 23,
                    input: 11775.544399867556,
                    performance: 9264.79836776979,
                    init_date: '2044-05-02',
                    end_date: '2045-04-02',
                },
                {
                    name: 24,
                    input: 12100.568069427016,
                    performance: 10015.825707657645,
                    init_date: '2045-05-02',
                    end_date: '2046-04-02',
                },
                {
                    name: 25,
                    input: 12425.591738986475,
                    performance: 10804.945253279939,
                    init_date: '2046-05-02',
                    end_date: '2047-04-02',
                },
                {
                    name: 26,
                    input: 12750.615408545935,
                    performance: 11633.505468719664,
                    init_date: '2047-05-02',
                    end_date: '2048-04-02',
                },
                {
                    name: 27,
                    input: 13075.639078105394,
                    performance: 12502.902553688362,
                    init_date: '2048-05-02',
                    end_date: '2049-04-02',
                },
                {
                    name: 28,
                    input: 13400.662747664854,
                    performance: 13414.58213336736,
                    init_date: '2049-05-02',
                    end_date: '2050-04-02',
                },
                {
                    name: 29,
                    input: 13725.686417224313,
                    performance: 14370.041008069391,
                    init_date: '2050-05-02',
                    end_date: '2051-04-02',
                },
                {
                    name: 30,
                    input: 14050.710086783773,
                    performance: 15370.828964838283,
                    init_date: '2051-05-02',
                    end_date: '2052-04-02',
                },
                {
                    name: 31,
                    input: 14375.733756343232,
                    performance: 16418.550653179198,
                    init_date: '2052-05-02',
                    end_date: '2053-04-02',
                },
                {
                    name: 32,
                    input: 14700.757425902691,
                    performance: 17514.867527189785,
                    init_date: '2053-05-02',
                    end_date: '2054-04-02',
                },
                {
                    name: 33,
                    input: 15025.781095462151,
                    performance: 18661.499856442744,
                    init_date: '2054-05-02',
                    end_date: '2055-04-02',
                },
                {
                    name: 34,
                    input: 15350.80476502161,
                    performance: 19860.228808053682,
                    init_date: '2055-05-02',
                    end_date: '2056-04-02',
                },
                {
                    name: 35,
                    input: 15675.82843458107,
                    performance: 21112.898602454043,
                    init_date: '2056-05-02',
                    end_date: '2057-04-02',
                },
                {
                    name: 36,
                    input: 15838.340269360799,
                    performance: 23337.651109710692,
                    init_date: '2057-05-02',
                    end_date: '2058-04-02',
                },
                {
                    name: 37,
                    input: 15838.340269360799,
                    performance: 25528.808068724644,
                    init_date: '2058-05-02',
                    end_date: '2059-04-02',
                },
                {
                    name: 38,
                    input: 15838.340269360799,
                    performance: 27559.056723107366,
                    init_date: '2059-05-02',
                    end_date: '2060-04-02',
                },
                {
                    name: 39,
                    input: 15838.340269360799,
                    performance: 29434.833405044104,
                    init_date: '2060-05-02',
                    end_date: '2061-04-02',
                },
                {
                    name: 40,
                    input: 15838.340269360799,
                    performance: 31162.57444672011,
                    init_date: '2061-05-02',
                    end_date: '2062-04-02',
                },
                {
                    name: 41,
                    input: 15838.340269360799,
                    performance: 32748.71618032063,
                    init_date: '2062-05-02',
                    end_date: '2063-04-02',
                },
                {
                    name: 42,
                    input: 15838.340269360799,
                    performance: 34199.69493803091,
                    init_date: '2063-05-02',
                    end_date: '2064-04-02',
                },
                {
                    name: 43,
                    input: 15838.340269360799,
                    performance: 35521.94705203621,
                    init_date: '2064-05-02',
                    end_date: '2065-04-02',
                },
                {
                    name: 44,
                    input: 15838.340269360799,
                    performance: 36721.908854521775,
                    init_date: '2065-05-02',
                    end_date: '2066-04-02',
                },
                {
                    name: 45,
                    input: 15838.340269360799,
                    performance: 37806.01667767285,
                    init_date: '2066-05-02',
                    end_date: '2067-04-02',
                },
                {
                    name: 46,
                    input: 15838.340269360799,
                    performance: 38780.70685367469,
                    init_date: '2067-05-02',
                    end_date: '2068-04-02',
                },
                {
                    name: 47,
                    input: 15838.340269360799,
                    performance: 39652.415714712544,
                    init_date: '2068-05-02',
                    end_date: '2069-04-02',
                },
                {
                    name: 48,
                    input: 15838.340269360799,
                    performance: 40427.57959297165,
                    init_date: '2069-05-02',
                    end_date: '2070-04-02',
                },
                {
                    name: 49,
                    input: 15838.340269360799,
                    performance: 41112.63482063727,
                    init_date: '2070-05-02',
                    end_date: '2071-04-02',
                },
                {
                    name: 50,
                    input: 15838.340269360799,
                    performance: 41714.01772989465,
                    init_date: '2071-05-02',
                    end_date: '2072-04-02',
                },
                {
                    name: 51,
                    input: 15838.340269360799,
                    performance: 42238.16465292904,
                    init_date: '2072-05-02',
                    end_date: '2073-04-02',
                },
                {
                    name: 52,
                    input: 15838.340269360799,
                    performance: 42676.47971657716,
                    init_date: '2073-05-02',
                    end_date: '2074-04-02',
                },
                {
                    name: 53,
                    input: 15838.340269360799,
                    performance: 43033.688835837,
                    init_date: '2074-05-02',
                    end_date: '2075-04-02',
                },
                {
                    name: 54,
                    input: 15838.340269360799,
                    performance: 43332.56924394426,
                    init_date: '2075-05-02',
                    end_date: '2076-04-02',
                },
                {
                    name: 55,
                    input: 15838.340269360799,
                    performance: 43579.95899456952,
                    init_date: '2076-05-02',
                    end_date: '2077-04-02',
                },
                {
                    name: 56,
                    input: 15838.340269360799,
                    performance: 43782.29441989804,
                    init_date: '2077-05-02',
                    end_date: '2078-04-02',
                },
                {
                    name: 57,
                    input: 15838.340269360799,
                    performance: 43946.011852115065,
                    init_date: '2078-05-02',
                    end_date: '2079-04-02',
                },
                {
                    name: 58,
                    input: 15838.340269360799,
                    performance: 44077.547623405844,
                    init_date: '2079-05-02',
                    end_date: '2080-04-02',
                },
                {
                    name: 59,
                    input: 15838.340269360799,
                    performance: 44183.33806595562,
                    init_date: '2080-05-02',
                    end_date: '2081-04-02',
                },
                {
                    name: 60,
                    input: 15838.340269360799,
                    performance: 44269.81951194966,
                    init_date: '2081-05-02',
                    end_date: '2082-04-02',
                },
                {
                    name: 61,
                    input: 15838.340269360799,
                    performance: 44310.24433961562,
                    init_date: '2082-05-02',
                    end_date: '2083-04-02',
                },
            ],
        },
        {
            id: 3,
            data: [
                {
                    name: 1,
                    input: 14209.163648241101,
                    performance: 107.0732295529906,
                    init_date: '2022-05-02',
                    end_date: '2023-04-02',
                },
                {
                    name: 2,
                    input: 14209.163648241101,
                    performance: 1565.539092179028,
                    init_date: '2023-05-02',
                    end_date: '2024-04-02',
                },
                {
                    name: 3,
                    input: 14209.163648241101,
                    performance: 2580.449864464028,
                    init_date: '2024-05-02',
                    end_date: '2025-04-02',
                },
                {
                    name: 4,
                    input: 14209.163648241101,
                    performance: 3257.561124050481,
                    init_date: '2025-05-02',
                    end_date: '2026-04-02',
                },
                {
                    name: 5,
                    input: 14209.163648241101,
                    performance: 3684.9857163716893,
                    init_date: '2026-05-02',
                    end_date: '2027-04-02',
                },
                {
                    name: 6,
                    input: 14209.163648241101,
                    performance: 3967.639088964949,
                    init_date: '2027-05-02',
                    end_date: '2028-04-02',
                },
            ],
        },
    ],
    withoutInveertChart: [
        {
            id: 1,
            data: [
                {
                    name: 1,
                    input: 1712.556096618113,
                    cumulative_input: 1712.556096618113,
                    init_date: '2022-05-02',
                    end_date: '2023-04-02',
                },
                {
                    name: 2,
                    input: 1712.556096618113,
                    cumulative_input: 3425.112193236226,
                    init_date: '2023-05-02',
                    end_date: '2024-04-02',
                },
                {
                    name: 3,
                    input: 1712.556096618113,
                    cumulative_input: 5137.668289854342,
                    init_date: '2024-05-02',
                    end_date: '2025-04-02',
                },
                {
                    name: 4,
                    input: 1712.556096618113,
                    cumulative_input: 6850.2243864724605,
                    init_date: '2025-05-02',
                    end_date: '2026-04-02',
                },
                {
                    name: 5,
                    input: 1712.556096618113,
                    cumulative_input: 8562.780483090577,
                    init_date: '2026-05-02',
                    end_date: '2027-04-02',
                },
                {
                    name: 6,
                    input: 1712.556096618113,
                    cumulative_input: 10275.336579708684,
                    init_date: '2027-05-02',
                    end_date: '2028-04-02',
                },
                {
                    name: 7,
                    input: 1712.556096618113,
                    cumulative_input: 11987.892676326792,
                    init_date: '2028-05-02',
                    end_date: '2029-04-02',
                },
                {
                    name: 8,
                    input: 1712.556096618113,
                    cumulative_input: 13700.4487729449,
                    init_date: '2029-05-02',
                    end_date: '2030-04-02',
                },
                {
                    name: 9,
                    input: 1712.556096618113,
                    cumulative_input: 15413.004869563007,
                    init_date: '2030-05-02',
                    end_date: '2031-04-02',
                },
                {
                    name: 10,
                    input: 1712.556096618113,
                    cumulative_input: 17125.560966181114,
                    init_date: '2031-05-02',
                    end_date: '2032-04-02',
                },
                {
                    name: 11,
                    input: 1712.556096618113,
                    cumulative_input: 18838.11706279922,
                    init_date: '2032-05-02',
                    end_date: '2033-04-02',
                },
                {
                    name: 12,
                    input: 1712.556096618113,
                    cumulative_input: 20550.67315941733,
                    init_date: '2033-05-02',
                    end_date: '2034-04-02',
                },
                {
                    name: 13,
                    input: 1712.556096618113,
                    cumulative_input: 22263.229256035436,
                    init_date: '2034-05-02',
                    end_date: '2035-04-02',
                },
                {
                    name: 14,
                    input: 1712.556096618113,
                    cumulative_input: 23975.785352653544,
                    init_date: '2035-05-02',
                    end_date: '2036-04-02',
                },
                {
                    name: 15,
                    input: 1712.556096618113,
                    cumulative_input: 25688.34144927165,
                    init_date: '2036-05-02',
                    end_date: '2037-04-02',
                },
                {
                    name: 16,
                    input: 1712.556096618113,
                    cumulative_input: 27400.89754588976,
                    init_date: '2037-05-02',
                    end_date: '2038-04-02',
                },
                {
                    name: 17,
                    input: 1712.556096618113,
                    cumulative_input: 29113.453642507866,
                    init_date: '2038-05-02',
                    end_date: '2039-04-02',
                },
                {
                    name: 18,
                    input: 1712.556096618113,
                    cumulative_input: 30826.009739125973,
                    init_date: '2039-05-02',
                    end_date: '2040-04-02',
                },
                {
                    name: 19,
                    input: 1712.556096618113,
                    cumulative_input: 32538.56583574408,
                    init_date: '2040-05-02',
                    end_date: '2041-04-02',
                },
                {
                    name: 20,
                    input: 1712.556096618113,
                    cumulative_input: 34251.12193236223,
                    init_date: '2041-05-02',
                    end_date: '2042-04-02',
                },
                {
                    name: 21,
                    input: 1712.556096618113,
                    cumulative_input: 35963.67802898038,
                    init_date: '2042-05-02',
                    end_date: '2043-04-02',
                },
                {
                    name: 22,
                    input: 1712.556096618113,
                    cumulative_input: 37676.23412559853,
                    init_date: '2043-05-02',
                    end_date: '2044-04-02',
                },
                {
                    name: 23,
                    input: 1712.556096618113,
                    cumulative_input: 39388.79022221668,
                    init_date: '2044-05-02',
                    end_date: '2045-04-02',
                },
                {
                    name: 24,
                    input: 1712.556096618113,
                    cumulative_input: 41101.34631883483,
                    init_date: '2045-05-02',
                    end_date: '2046-04-02',
                },
                {
                    name: 25,
                    input: 1712.556096618113,
                    cumulative_input: 42813.90241545298,
                    init_date: '2046-05-02',
                    end_date: '2047-04-02',
                },
                {
                    name: 26,
                    input: 1712.556096618113,
                    cumulative_input: 44526.458512071134,
                    init_date: '2047-05-02',
                    end_date: '2048-04-02',
                },
                {
                    name: 27,
                    input: 1712.556096618113,
                    cumulative_input: 46239.014608689286,
                    init_date: '2048-05-02',
                    end_date: '2049-04-02',
                },
                {
                    name: 28,
                    input: 1712.556096618113,
                    cumulative_input: 47951.57070530744,
                    init_date: '2049-05-02',
                    end_date: '2050-04-02',
                },
                {
                    name: 29,
                    input: 1712.556096618113,
                    cumulative_input: 49664.12680192559,
                    init_date: '2050-05-02',
                    end_date: '2051-04-02',
                },
                {
                    name: 30,
                    input: 1712.556096618113,
                    cumulative_input: 51376.68289854374,
                    init_date: '2051-05-02',
                    end_date: '2052-04-02',
                },
                {
                    name: 31,
                    input: 1712.556096618113,
                    cumulative_input: 53089.23899516189,
                    init_date: '2052-05-02',
                    end_date: '2053-04-02',
                },
                {
                    name: 32,
                    input: 1712.556096618113,
                    cumulative_input: 54801.79509178004,
                    init_date: '2053-05-02',
                    end_date: '2054-04-02',
                },
                {
                    name: 33,
                    input: 1712.556096618113,
                    cumulative_input: 56514.35118839819,
                    init_date: '2054-05-02',
                    end_date: '2055-04-02',
                },
                {
                    name: 34,
                    input: 1712.556096618113,
                    cumulative_input: 58226.90728501634,
                    init_date: '2055-05-02',
                    end_date: '2056-04-02',
                },
                {
                    name: 35,
                    input: 1712.556096618113,
                    cumulative_input: 59939.463381634494,
                    init_date: '2056-05-02',
                    end_date: '2057-04-02',
                },
                {
                    name: 36,
                    input: 856.2780483090565,
                    cumulative_input: 60795.74142994357,
                    init_date: '2057-05-02',
                    end_date: '2057-10-02',
                },
            ],
        },
        {
            id: 3,
            data: [
                {
                    name: 1,
                    input: 18176.80273720605,
                    cumulative_input: 18176.80273720605,
                    init_date: '2022-05-02',
                    end_date: '2023-04-02',
                },
            ],
        },
    ],
    equityChart: [
        {
            id: 9,
            data: {
                '2022': {
                    name: 2022,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2023': {
                    name: 2023,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2024': {
                    name: 2024,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2025': {
                    name: 2025,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2026': {
                    name: 2026,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2027': {
                    name: 2027,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2028': {
                    name: 2028,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2029': {
                    name: 2029,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2030': {
                    name: 2030,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2031': {
                    name: 2031,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2032': {
                    name: 2032,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2033': {
                    name: 2033,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2034': {
                    name: 2034,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2035': {
                    name: 2035,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2036': {
                    name: 2036,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2037': {
                    name: 2037,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2038': {
                    name: 2038,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2039': {
                    name: 2039,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2040': {
                    name: 2040,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2041': {
                    name: 2041,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2042': {
                    name: 2042,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2043': {
                    name: 2043,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2044': {
                    name: 2044,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2045': {
                    name: 2045,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2046': {
                    name: 2046,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2047': {
                    name: 2047,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2048': {
                    name: 2048,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2049': {
                    name: 2049,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2050': {
                    name: 2050,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2051': {
                    name: 2051,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2052': {
                    name: 2052,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2053': {
                    name: 2053,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2054': {
                    name: 2054,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2055': {
                    name: 2055,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2056': {
                    name: 2056,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2057': {
                    name: 2057,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2058': {
                    name: 2058,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2059': {
                    name: 2059,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2060': {
                    name: 2060,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2061': {
                    name: 2061,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2062': {
                    name: 2062,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2063': {
                    name: 2063,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2064': {
                    name: 2064,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2065': {
                    name: 2065,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2066': {
                    name: 2066,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2067': {
                    name: 2067,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2068': {
                    name: 2068,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2069': {
                    name: 2069,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2070': {
                    name: 2070,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2071': {
                    name: 2071,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2072': {
                    name: 2072,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2073': {
                    name: 2073,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2074': {
                    name: 2074,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2075': {
                    name: 2075,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2076': {
                    name: 2076,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2077': {
                    name: 2077,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2078': {
                    name: 2078,
                    equity: 0.8,
                    fixed_income: 0.2,
                },
                '2079': {
                    name: 2079,
                    equity: 0.66,
                    fixed_income: 0.34,
                },
                '2080': {
                    name: 2080,
                    equity: 0.5,
                    fixed_income: 0.5,
                },
                '2081': {
                    name: 2081,
                    equity: 0.28,
                    fixed_income: 0.72,
                },
                '2082': {
                    name: 2082,
                    equity: 0.08,
                    fixed_income: 0.92,
                },
            },
        },
        {
            id: 7,
            data: {
                '2022': {
                    name: 2022,
                    equity: 0.08,
                    fixed_income: 0.92,
                },
            },
        },
    ],
    userIncome: {
        total: 3000,
        type: null,
        business_income: [],
        pension_type: null,
        pension_income: [],
        partner_type: null,
        partner_pension_type: null,
        has_partner: null,
    },
    name: 'Pablo',
    riskProfileMax: {
        identifier: '5',
        name: 'atrevido',
        alias: 'atrevido',
        max_equity_exposure: 0.8,
        main: true,
        is_active: true,
    },
    riskProfile: {
        identifier: '5',
        name: 'atrevido',
        alias: 'atrevido',
        max_equity_exposure: 0.8,
        main: true,
        is_active: true,
    },
    riskProfiles: [
        {
            identifier: '1',
            name: 'conservador',
            alias: 'conservador',
            max_equity_exposure: 0.08,
        },
        {
            identifier: '2',
            name: 'tranquilo',
            alias: 'tranquilo',
            max_equity_exposure: 0.28,
        },
        {
            identifier: '3',
            name: 'equilibrado',
            alias: 'equilibrado',
            max_equity_exposure: 0.5,
        },
        {
            identifier: '4',
            name: 'decidido',
            alias: 'decidido',
            max_equity_exposure: 0.66,
        },
        {
            identifier: '5',
            name: 'atrevido',
            alias: 'atrevido',
            max_equity_exposure: 0.8,
        },
        {
            identifier: '6',
            name: 'super',
            alias: 'super',
            max_equity_exposure: 1,
        },
    ],
    config: [
        {
            name: 'FIXED_INCOME_PERFORMANCE',
            value: [
                1.31, 1.48, 1.53, 1.55, 1.56, 1.57, 1.58, 1.58, 1.59, 1.59, 1.59, 1.6, 1.6, 1.6, 1.6, 1.6, 1.6, 1.6,
                1.61, 1.61,
            ],
            description: 'Rentabilidad que se aplica a la renta fija',
        },
        {
            name: 'MONETARY_PERFORMANCE',
            value: 0.23,
            description: 'Rentabilidad que se aplica a los activos monetarios',
        },
        {
            name: 'DEPOSIT_PERFORMANCE',
            value: 0.3,
            description: 'Rentabilidad que se aplica a los depósitos',
        },
        {
            name: 'DEATH_AGE_MAN',
            value: 90,
            description: 'Edad esperada de defunción para género masculino',
        },
        {
            name: 'DEATH_AGE_WOMAN',
            value: 92,
            description: 'Edad esperada de defunción para género femenino',
        },
        {
            name: 'INCREMENT_OBJECTIVES',
            value: 0.016,
            description: 'Tasa de incremento de ahorro de objetivos',
        },
        {
            name: 'INFLATION',
            value: 0.015,
            description: 'Tasa de inflación anual',
        },
        {
            name: 'RECOMMENDATION_TYPE',
            value: 'FIXED_SERVICES_PLAYABLE_PRODUCTS',
            description: 'Tipo de recomendación de servicio que se dará después de la planificación',
        },
        {
            name: 'MAX_PENSION_PLAN_CONTRIBUTION',
            value: 2000,
            description: 'Contribución máxima anual a los servicios de planes de pensiones',
        },
        {
            name: 'MIN_INVESTMENT_SERVICES',
            value: {
                mutual: 3000,
                deposit: 5000,
                pension: 30,
            },
            description: 'Inversión mínima a realizar por cada uno de los servicios disponibles',
        },
        {
            name: 'SAVING_FOR_PENSION',
            value: false,
            description:
                'Esta variable indica si se puede aportar a los planes de pensiones o si simplemente se puede realizar un traspaso y rentabilizar.',
        },
        {
            name: 'PERCENTILE',
            value: 25,
            description:
                'Esta variable indica el percentil que se está usando para obtener las rentabilidades de la renta variable',
        },
        {
            name: 'EQUITY_EXPECTED_PERFORMANCE',
            value: [-1.5, 1.1, 1.6, 0.7, 1, 1.8, 2.3, 2.9, 3.7, 3.9, 3.5, 3.9, 4.1, 4, 4.1, 4.2, 4.3, 4.4, 4.5, 4.6],
            description: 'Rentabilidad esperada de la renta variable',
        },
        {
            name: 'EQUITY_MAX_PERFORMANCE',
            value: [
                70.6, 52.9, 40.6, 29.5, 31.6, 25.2, 24.5, 20.4, 17.5, 16.6, 15.7, 15.1, 15.5, 14.6, 15.1, 15.2, 15,
                14.7, 13.1, 13.1,
            ],
            description: 'Rentabilidad máxima de la renta variable',
        },
        {
            name: 'EQUITY_MIN_PERFORMANCE',
            value: [
                -51, -32, -19.4, -12.6, -8, -2.6, -5.4, -5.4, -7.6, -5.4, -3.9, -1.9, -0.8, 0.4, 0.8, 0.4, 1.2, 1.8,
                1.3, 1.6,
            ],
            description: 'Rentabilidad mínima de la renta variable',
        },
        {
            name: 'CURRENCY',
            value: 'EUR',
            description: 'Moneda a utilizar en la plataforma',
        },
        {
            name: 'CURRENCY_SYMBOL',
            value: process.env.REACT_APP_CURRENCY_SYMBOL || '€',
            description: 'Símbolo de la moneda a utilizar en la plataforma',
        },
        {
            name: 'MEAN_PERFORMANCES_VECTOR',
            value: [
                0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016,
                0.016, 0.016, 0.016, 0.016, 0.016,
            ],
            description: 'Vector de rentabilidades media que nos proporciona la entidad',
        },
    ],
    userExpenses: {
        total: 2000,
        fixed_expenses: null,
        holidays: null,
        children_expenses: [],
    },
    document: '98061552V',
    email: 'prueba+prueba@gmail.com',
    phone: 646333333,
};

export const propuestaWithGlobalInfoApiResponse = {
    data: {
        attributes: {
            created_at: '2022-08-24T16:28:32+02:00',
            initial_investment: 7104,
            pension_plan: 56154,
            updated_at: '2022-08-24T16:28:32+02:00',
            user_id: 5,
        },
        id: '1',
        type: 'Plan',
    },
    included: [
        {
            attributes: {
                contributed: 0,
                amount: 0,
                pending: 0,
                user_input_initial_investment: 0,
                user_input_pension_plan: 0,
                taxation: 0,
                goal_cost: 0,
                goal_cost_with_taxation: 0,
                initial_investment: 0,
                pension_plan: 0,
                monthly_saving: 0,
                performance: 0,
                remaining_saving: 0,
                saving: 0,
                totalInvestment: 0,
            },
            id: '1',
            type: 'GlobalInfo',
        },
    ],
    jsonapi: {
        version: '1.0',
    },
    links: {
        self: '/api/v2/plan/v1/plans/1',
    },
};

export const inputsChartApiResponse = {
    data: [
        {
            attributes: {
                date: '2022-08-24',
                goal_id: 3,
                periodicity: 12,
            },
            id: '3',
            type: 'MonthlySavings',
        },
        {
            attributes: {
                date: '2022-08-24',
                goal_id: 2,
                periodicity: 12,
            },
            id: '2',
            type: 'MonthlySavings',
        },
        {
            attributes: {
                date: '2022-08-24',
                goal_id: 1,
                periodicity: 12,
            },
            id: '1',
            type: 'MonthlySavings',
        },
    ],
    included: [
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2022',
            },
            id: '2022/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2023',
            },
            id: '2023/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2024',
            },
            id: '2024/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2025',
            },
            id: '2025/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2026',
            },
            id: '2026/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2027',
            },
            id: '2027/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2028',
            },
            id: '2028/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2029',
            },
            id: '2029/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2030',
            },
            id: '2030/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2031',
            },
            id: '2031/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2032',
            },
            id: '2032/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2033',
            },
            id: '2033/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2034',
            },
            id: '2034/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2035',
            },
            id: '2035/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2036',
            },
            id: '2036/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2037',
            },
            id: '2037/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2038',
            },
            id: '2038/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 0,
                goal_id: 3,
                monthly_saving: 0,
                year: '2039',
            },
            id: '2039/3',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 3537.9731357735927,
                goal_id: 2,
                monthly_saving: 294.8310946477994,
                year: '2022',
            },
            id: '2022/2',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 3594.5807059459703,
                goal_id: 2,
                monthly_saving: 299.5483921621642,
                year: '2023',
            },
            id: '2023/2',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 3652.093997241105,
                goal_id: 2,
                monthly_saving: 304.3411664367588,
                year: '2024',
            },
            id: '2024/2',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 3710.5275011969634,
                goal_id: 2,
                monthly_saving: 309.21062509974695,
                year: '2025',
            },
            id: '2025/2',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 3769.895941216115,
                goal_id: 2,
                monthly_saving: 314.1579951013429,
                year: '2026',
            },
            id: '2026/2',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 3830.214276275573,
                goal_id: 2,
                monthly_saving: 319.1845230229644,
                year: '2027',
            },
            id: '2027/2',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 3891.4977046959816,
                goal_id: 2,
                monthly_saving: 324.2914753913318,
                year: '2028',
            },
            id: '2028/2',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 3953.7616679711177,
                goal_id: 2,
                monthly_saving: 329.48013899759314,
                year: '2029',
            },
            id: '2029/2',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 22598.68907306521,
                goal_id: 1,
                monthly_saving: 1883.2240894221009,
                year: '2022',
            },
            id: '2022/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 22960.268098234254,
                goal_id: 1,
                monthly_saving: 1913.3556748528545,
                year: '2023',
            },
            id: '2023/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 23327.632387806003,
                goal_id: 1,
                monthly_saving: 1943.9693656505003,
                year: '2024',
            },
            id: '2024/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 23700.8745060109,
                goal_id: 1,
                monthly_saving: 1975.0728755009081,
                year: '2025',
            },
            id: '2025/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 24080.088498107074,
                goal_id: 1,
                monthly_saving: 2006.6740415089228,
                year: '2026',
            },
            id: '2026/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 24465.369914076786,
                goal_id: 1,
                monthly_saving: 2038.7808261730656,
                year: '2027',
            },
            id: '2027/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 24856.815832702014,
                goal_id: 1,
                monthly_saving: 2071.4013193918345,
                year: '2028',
            },
            id: '2028/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 25254.524886025247,
                goal_id: 1,
                monthly_saving: 2104.543740502104,
                year: '2029',
            },
            id: '2029/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 25658.597284201656,
                goal_id: 1,
                monthly_saving: 2138.216440350138,
                year: '2030',
            },
            id: '2030/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 26069.13484074888,
                goal_id: 1,
                monthly_saving: 2172.42790339574,
                year: '2031',
            },
            id: '2031/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 26486.24099820086,
                goal_id: 1,
                monthly_saving: 2207.186749850072,
                year: '2032',
            },
            id: '2032/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 26910.02085417207,
                goal_id: 1,
                monthly_saving: 2242.5017378476728,
                year: '2033',
            },
            id: '2033/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 27340.581187838834,
                goal_id: 1,
                monthly_saving: 2278.381765653236,
                year: '2034',
            },
            id: '2034/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 27778.03048684425,
                goal_id: 1,
                monthly_saving: 2314.8358739036876,
                year: '2035',
            },
            id: '2035/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 28222.47897463376,
                goal_id: 1,
                monthly_saving: 2351.8732478861466,
                year: '2036',
            },
            id: '2036/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 28674.0386382279,
                goal_id: 1,
                monthly_saving: 2389.503219852325,
                year: '2037',
            },
            id: '2037/1',
            type: 'Saving',
        },
        {
            attributes: {
                annual_saving: 29132.823256439544,
                goal_id: 1,
                monthly_saving: 2427.735271369962,
                year: '2038',
            },
            id: '2038/1',
            type: 'Saving',
        },
    ],
    jsonapi: {
        version: '1.0',
    },
    links: {
        self: '/api/v2/plan/v1/plans/1/charts/monthly-savings',
    },
};

export const globalInfoWithoutInveert = {
    data: {
        attributes: {
            monthly_saving: 2500,
            total_contributed: 30000,
        },
        id: 1,
        type: 'SavingWithoutInvestment',
    },
    jsonapi: {
        version: '1.0',
    },
    links: {
        self: '/api/v2/plan/v1/plans/1/charts/monthly-savings',
    },
};

export const faqRecomendacion = [
    {
        title: tr('¿Es ahora un buen momento para invertir?'),
        text: (
            <>
                <p>
                    {tr(
                        'Si.Como la bolsa ha caído desde finales de 2021, ahora es un buen momento para empezar. Piensa que invertirías a precios más bajos y tienes un mayor potencial de rentabilidad a largo plazo. Creemos que la rentabilidades que estimamos son conservadoras para el horizonte temporal de tu plan.'
                    )}
                </p>
                <p>
                    {tr(
                        'Entendemos qué la situación actual puede dar miedo y no es agradable. La elevada inflación, los bancos centrales subiendo los tipos de interés y la guerra entre Rusia y Ucrania. No son buenas noticias, pero ya hemos vivido momentos peores, como la crisis financiera de 2008 o el COVID. Y al final todo pasa.'
                    )}
                </p>
            </>
        ),
    },
    {
        title: tr('¿Cómo elaboráis la recomendación de mi cartera de inversión?'),
        text: (
            <>
                <p>{tr('Hemos seguido cuatro pasos, por este orden:')}</p>
                <ol>
                    <li>
                        <p>
                            {tr(
                                'Todo parte de tu perfil inversor. Con el test que has pasado hemos evaluado tu experiencia inversora y tu situación financiera. Esto determina la máxima exposición a riesgo de mercado que puedes asumir como inversor.'
                            )}
                        </p>
                    </li>
                    <li>
                        <p>
                            {tpl(
                                tr(
                                    'En {siteTitle} hacemos un segundo ajuste del riesgo, para minimizar la posibilidad de que pierdas dinero al final del periodo planificado. Para ello tenemos muy en cuenta los años de horizonte temporal del plan. De manera que si tu horizonte temporal es elevado te recomendamos invertir al máximo de renta variable (bolsa) que permita tu perfil inversor, pero si la última meta de tu plan es a corto plazo, te haremos una propuesta más conservadora.'
                                ),
                                {siteTitle: process.env.REACT_APP_SITE_TITLE ?? 'la entidad'}
                            )}{' '}
                        </p>
                    </li>
                    <li>
                        <p>
                            {tr(
                                'Como consecuencia de lo anterior, hemos seleccionado la cartera de Finanbest que consideramos óptima según tu perfil inversor y el horizonte temporal de tu plan. La estrategia de inversión de esta cartera la define un comité de expertos que se reúne mensualmente. Esto es importante porque el 80% de la rentabilidad a largo plazo viene determinada por la correcta distribución de sus activos.'
                            )}{' '}
                        </p>
                    </li>
                    <li>
                        <p>
                            {tr(
                                'Los productos financieros que conforman tu cartera se seleccionan bajo estrictos requisitos cuantitativos y cualitativos, buscando siempre que pagues las menores comisiones posibles. Una correcta selección de fondos puede suponer una mejora de rentabilidad anual de un 3% para el inversor medio español (un 40% adicional de rentabilidad a 10 años).'
                            )}{' '}
                        </p>
                    </li>
                </ol>
            </>
        ),
    },
    {
        title: tr('¿Cómo se gestionan las inversiones de la cartera?'),
        text: (
            <>
                <p>
                    {tpl(
                        tr(
                            'El servicio que te ofrecemos es una Gestión Delegada de la cartera que {siteTitle} te ha recomendado.'
                        ),
                        {siteTitle: process.env.REACT_APP_SITE_TITLE ?? 'la entidad'}
                    )}
                </p>
                <p>
                    {tpl(
                        tr(
                            'Somos agentes regulados (por la CNMV) de {entity}, entidad que tomará todas las decisiones relativas a la composición y ejecución de las órdenes de compra y venta, con tal de alcanzar la mejor rentabilidad/riesgo para tu cartera.'
                        ),
                        {entity: 'Finanbest'}
                    )}
                </p>
                <p>
                    {tpl(
                        tr(
                            'Es decir {siteTitle} recomienda tu cartera, {entity} la gestiona y tú no tienes que hacer nada.'
                        ),
                        {siteTitle: process.env.REACT_APP_SITE_TITLE ?? 'la entidad', entity: 'Finanbest'}
                    )}
                </p>
                <p>
                    {tr(
                        'A nivel estratégico, el comité de inversiones, formado por expertos de reconocido prestigio, se reúne mensualmente para analizar la evolución del mercado y proponer cambios en la distribución de activos de la cartera, si se considera necesario.'
                    )}
                </p>
                <p>
                    {tr(
                        'A nivel táctico, se hacen reajustes automáticos de la composición de la cartera. Esto significa que periódicamente compraremos de los productos que hayan subido más y vendemos de los que hayan caído. Así te aseguras que el nivel de riesgo y de rentabilidad de la cartera se mantiene dentro de los parámetros definidos, con independencia de la evolución de los mercados. Según numerosos estudios, esto significa una rentabilidad adicional de +0,4% al año (o un 10% al cabo de 10 años en comparación con una cartera sin reajustes).'
                    )}{' '}
                </p>
            </>
        ),
    },
];
export const faqPlan = [
    {
        title: tr('¿Cómo calculáis la aportación mensual de mi plan?'),
        text: (
            <>
                <p>
                    {tpl(tr('El algoritmo de {siteTitle} lo calcula en tres pasos:'), {
                        siteTitle: process.env.REACT_APP_SITE_TITLE ?? 'la entidad',
                    })}
                </p>
                <ol>
                    <li>
                        <p>
                            {tpl(
                                tr(
                                    'Tú defines el dinero que necesitarás para tus metas y cuando lo necesitas. {siteTitle} añade el efecto de un {inflation}% anual (porque no es lo mismo 20.000€ de hoy, que 20.000 € en 10 años).'
                                ),
                                {siteTitle: process.env.REACT_APP_SITE_TITLE ?? 'la entidad', inflation: 2}
                            )}
                        </p>
                    </li>
                    <li>
                        <p>
                            {tr(
                                'Calculamos también los impuestos que tendrías que pagar según la rentabilidad esperada de la cartera que te estamos recomendando. De esta manera, cuando toque rescatar tu dinero en el futuro obtendrás la cantidad que realmente necesites para conseguir tus metas, neta de IRPF.'
                            )}{' '}
                        </p>
                    </li>

                    <li>
                        <p>
                            {tr(
                                'El ahorro que has indicado que aportarás inicialmente lo distribuimos de manera desigual entre tus metas. Priorizamos tus metas a corto plazo asignando un porcentaje (%) mayor de tus ahorros a éstas.'
                            )}{' '}
                        </p>
                    </li>
                </ol>
                <p>
                    {tr(
                        'Con todo lo anterior, nuestro algoritmo calcula la aportación mensual para cada una de tus metas y la suma de éstas es la aportación mensual total de tu plan.'
                    )}{' '}
                </p>
                <p>
                    {tpl(
                        tr(
                            'Verás que tu aportación sube un {percentage}% cada año. Lo hacemos porque es el incremento medio del salario en España durante los últimos 20 años, según el Instituto nacional de estadística (INE). Lo normal es que tus ingresos aumenten en el futuro. Así empiezas aportando un poco menos hoy y lo vamos ajustando poco a poco.'
                        ),
                        {percentage: '2,1'}
                    )}
                </p>
            </>
        ),
    },
    {
        title: tr('Si mi aportación mensual puede ser mayor/menor a la que indicáis, ¿qué hago?'),
        text: (
            <>
                <p>
                    {tr(
                        'Si tienes más capacidad de ahorro mensual, te recomendamos que seas más ambicioso/a al planificar tus metas: más dinero o conseguirlas antes.'
                    )}
                </p>
                <p>
                    {tr(
                        'Si tienes menos capacidad de ahorro mensual, te recomendamos que planifiques metas menos ambiciosas. Otra opción es aumentar tu aportación inicial. Con estas medidas te saldrá una aportación mensual menor.'
                    )}
                </p>
                <p>
                    {tr(
                        'Puedes hacer todas las simulaciones que quieras, modificando tu aportación mensual, el ahorro inicial, tu perfil de inversor o las metas.'
                    )}
                </p>
            </>
        ),
    },
    {
        title: tr('¿Y si luego tengo un imprevisto y tengo que parar mi aportación mensual?'),
        text: (
            <>
                <p>
                    {tr(
                        'No pasa nada. Te recomendamos que dejes de aportar hasta que vuelvas a una situación de normalidad. Cuando eso ocurra, te ofreceremos dos alternativas para ponerte al día:'
                    )}
                </p>
                <ul>
                    <li>
                        <p>
                            {tr(
                                'Reseteamos tu plan financiero y re-calculamos las aportaciones. Puede que tu siguiente aportación sea un poquito más elevada, pero nada que te rompa el mes.'
                            )}
                        </p>
                    </li>
                    <li>
                        <p>{tr('Haces una aportación extraordinaria por el importe que esté pendiente.')}</p>
                    </li>
                </ul>
                <p>
                    {tr(
                        'Y si esa situación se alarga o se convierte en permanente, podrás modificar tus metas cuando quieras y te recomendaremos un nuevo plan.'
                    )}
                </p>
            </>
        ),
    },
];
export const faqRentabilidad = [
    {
        title: tr('¿Cómo calculáis la rentabilidad estimada de mi plan?'),
        text: (
            <>
                <p>
                    {tr(
                        'Contamos con un comité de inversiones formado por expertos de reconocido prestigio. Nos basamos en el análisis histórico de múltiples mercados y activos financieros a lo largo de más de 50 años de historia, en los que ha habido periodos de crisis y de prosperidad.'
                    )}
                </p>
                <p>
                    {tr(
                        'Rentabilidades pasadas no garantizan rentabilidades futuras, pero las rentabilidades medias históricas siempre se han movido en rangos similares, incluso en momentos de crisis como la del petróleo (1973), lunes negro (1987), burbuja japonesa (años 90), miércoles negro (1992), crisis asiática (1997), crisis financiera (2008), el COVID-19 (2020) o la reciente guerra de Ucrania (2022-…).'
                    )}
                </p>
                <p>
                    {tr(
                        'No debes esperar esa rentabilidad esperada cada año. Seguro que habrá años buenos y años malos. Lo importante es que existe una probabilidad muy alta de conseguir esa rentabilidad media estimada al final, cumpliendo así con el plan que te proponemos.'
                    )}
                </p>
            </>
        ),
    },
    {
        title: tr('¿Y si la rentabilidad evoluciona mejor/peor de lo planificado?'),
        text: (
            <>
                <p>
                    {tr(
                        'Lo normal es que la rentabilidad media de tu plan termine siendo muy similar a la rentabilidad esperada si esperas hasta el final. Otra cosa muy diferente es lo que ocurrirá a lo largo del camino. Como los mercados son muy volátiles en el corto plazo, habrá años con rentabilidades buenas y otros con rentabilidades malas.'
                    )}
                </p>
                <p>
                    {tpl(
                        tr(
                            'Por eso desde {siteTitle} os enviaremos recomendaciones personalizadas para ajustar la estrategia de ahorro o de inversión si el plan se desvía mucho, para bien o para mal.'
                        ),
                        {siteTitle: process.env.REACT_APP_SITE_TITLE ?? 'la entidad'}
                    )}
                </p>
                <p>{tr('Buscamos tu tranquilidad, por eso vamos a estar a tu lado todo el camino.')}</p>
            </>
        ),
    },
    {
        title: tr('¿Puedo cambiar el Perfil de Riesgo?'),
        text: (
            <>
                <p>
                    {tr(
                        'Si.La ley MiFID II permite que puedas seleccionar un Perfil de Riesgo igual o más conservador al de tu Perfil Inversor. Aunque te recomendamos que mantengas el Perfil que te corresponde tras el test.'
                    )}
                </p>
                <p>
                    {tr(
                        'En el caso de que quieras optar a un perfil más arriesgado tendrás que volver a pasar el test de idoneidad y demostrar una mayor experiencia y conocimiento inversor.'
                    )}
                </p>
            </>
        ),
    },
];

export const notAccumulatedNetWorthApiResponse = {
    data: [
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 5.405124367621681,
            },
            id: '1',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 10.907633300008229,
            },
            id: '2',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 16.50928138277311,
            },
            id: '3',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 22.211854814041317,
            },
            id: '4',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 28.017171974014502,
            },
            id: '5',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 33.92708400479819,
            },
            id: '6',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 39.94347540067548,
            },
            id: '7',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 46.068264609016126,
            },
            id: '8',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 52.30340464201197,
            },
            id: '9',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 58.650883699434075,
            },
            id: '10',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 65.11272580261033,
            },
            id: '11',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 71.69099143982521,
            },
            id: '12',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 78.3877782233481,
            },
            id: '13',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 85.20522155829894,
            },
            id: '14',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 92.14549532356523,
            },
            id: '15',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 99.21081256498684,
            },
            id: '16',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 106.40342620103041,
            },
            id: '17',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 3,
                annual_net_worth: 113.72562974117756,
            },
            id: '18',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 2,
                annual_net_worth: 124.52346177598338,
            },
            id: '19',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 2,
                annual_net_worth: 301.6041340968768,
            },
            id: '20',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 2,
                annual_net_worth: 528.5920398588933,
            },
            id: '21',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 2,
                annual_net_worth: 801.7482857078758,
            },
            id: '22',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 2,
                annual_net_worth: 1117.7882591324233,
            },
            id: '23',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 2,
                annual_net_worth: 1475.500685372146,
            },
            id: '24',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 2,
                annual_net_worth: 1871.2213569443811,
            },
            id: '25',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 2,
                annual_net_worth: 2286.8628231492694,
            },
            id: '26',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 225.34976276582893,
            },
            id: '27',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 857.9125523043185,
            },
            id: '28',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 1898.700019915981,
            },
            id: '29',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 3352.31255243154,
            },
            id: '30',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 5225.498620747792,
            },
            id: '31',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 7528.667644562374,
            },
            id: '32',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 10273.058215894387,
            },
            id: '33',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 13466.913674801239,
            },
            id: '34',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 17126.26089189225,
            },
            id: '35',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 21266.36842269203,
            },
            id: '36',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 25880.29259971244,
            },
            id: '37',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 30950.370039561007,
            },
            id: '38',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 36457.82864318078,
            },
            id: '39',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 42375.76250710944,
            },
            id: '40',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 48680.685029641085,
            },
            id: '41',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 55364.64992967842,
            },
            id: '42',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 62401.366658143175,
            },
            id: '43',
            type: 'AnnualNetWorthNotAccumulated',
        },
        {
            attributes: {
                goal_id: 1,
                annual_net_worth: 69656.58768163441,
            },
            id: '44',
            type: 'AnnualNetWorthNotAccumulated',
        },
    ],
    jsonapi: {
        version: '1.0',
    },
    links: {
        self: '/api/v2/plan/v1/plans/1/charts/annual-net-worth-not-accumulated',
    },
};

export default propuestaMockedData;
