import * as React from 'react';
import * as yup from 'yup';
import {differenceInYears, format, parseISO, sub} from 'date-fns';
import {useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
// @ts-ignore
import {useNavigate} from 'react-router-dom';
import {selectUser, setRiskProfile, setUser} from '../../modules/user/user.slice';
import SolidDividers from '../../elements/dividers/SolidDividers';
import DotStepsFragment from '../../elements/fragments/DotStepsFragment';
import {profileFormQuestionTitle, profileQuestionNameOrder} from '../../utils/componentData';
// eslint-disable-next-line import/no-cycle
import {RiskProfileForm} from '../../modules/risk/risk-form.component';
import {
    getAllRiskProfilesApi,
    getFinancialProviderId,
    getQuestionsByMifidId,
    getRiskProfile,
    getTestMifidId,
    postQuestion,
} from '../../modules/risk/risk.service';
import PageLoading from '../../elements/loading/PageLoading';
import {getObjectives} from '../../modules/goal/goal.service';
import {setIsAuthDialogOpen} from '../../modules/auth/auth.slice';
import {addAlert} from '../../modules/app/app.slice';
import {selectPlan} from '../../modules/propuesta/propuesta.slice';
import {RiskQuestion} from '../../modules/risk/risk.type';
import {questionsOfTestMifid, riskProfileQuestionsByQuestionIndex} from '../../modules/risk/risk.data';
import {getErrorMessage, tr, tpl} from '../../utils/functions';
import perfil from '../../styles/Perfil.module.css';
import '@inveert/ui-kit/src/grid/grid-row';
import '@inveert/ui-kit/src/grid/grid-col';
import '@inveert/ui-kit/src/text/text-l';
import '@inveert/ui-kit/src/text/text-xl';
import '@inveert/ui-kit/src/text/text-m';
import goalFooterMeta from '../../styles/GoalFooterMeta.module.css';
import {getUserIdApi, patchExistingUserNamePcAndBirthday} from '../../modules/user/user.service';
import {getConfig} from '../../modules/propuesta/propuesta.service';

function Perfil(props: any) {
    const {initialInvestment, pensionPlan} = useSelector(selectPlan);
    const {finish, hideShowTest, goBack} = props;

    const dispatch = useDispatch();
    const router = useNavigate();
    const user = useSelector(selectUser);
    const [loading, setLoading] = React.useState(true);
    const [testId, setTestId] = React.useState('1');
    const [objectives, setObjectives] = React.useState([]);
    const [questionIndex, setQuestionIndex] = React.useState(0);
    const [apiQuestionIndex, setApiQuestionIndex] = React.useState(0);
    const [questions, setQuestions] = React.useState<Array<RiskQuestion>>(questionsOfTestMifid.data);
    React.useEffect(() => {
        const logo = document.getElementById('header-onboarding');
        if (logo) {
            logo.scrollIntoView({behavior: 'smooth'});
        }
    }, [questionIndex]);
    const optionRequiredText = tr('Tienes que introducir una opción');
    const [hideBirthdayQuestion, setHideBirthdayQuestion] = React.useState(true);
    const [riskProfileArray, setRiskProfileArray] = React.useState<Array<any>>([]);
    const validationSchema = yup.object({
        stability: yup.number().oneOf([1, 2, 3]).required(tr('Tienes que introducir una opción')),
        expenses: yup.number().required(tr('Necesitamos saber tus gastos')),
        wealth: yup
            .number()
            .nullable()
            .required(tr('Introduce un valor numérico'))
            .min(0, tr('La cantidad debe ser positiva'))
            .max(9999999999999.99, tr('La cantidad excede el máximo permitido')),
        // retirement_age is term on RETIREMENT
        studies: yup.number().oneOf([1, 2, 3]).required(optionRequiredText),
        years_investing: yup.number().oneOf([1, 2, 3, 4]).required(optionRequiredText),
        experience: yup.number().oneOf([1, 2]).required(optionRequiredText),
        goal: yup.number().oneOf([1, 2, 3]).required(optionRequiredText),
        risk: yup.number().oneOf([1, 2, 3, 4]).required(optionRequiredText),
        attitude: yup.number().oneOf([1, 2, 3, 4]).required(optionRequiredText),
        name: yup.string().max(20).required(tr('Introduce tu nombre')),
        birthday: yup
            .date()
            .typeError(tr('La fecha no es valida'))
            .nullable()
            .min(sub(Date.now(), {years: 90}), tr('Debes tener menos de 90'))
            .max(sub(Date.now(), {years: 18}), tr('Debes ser mayor de edad'))
            .required(tr('Fecha de nacimiento requerida')),
        zip_code: yup
            .string()
            .required(tr('Introduce tu código postal'))
            .matches(/^(?:0?[1-9]|[1-4]\d|5[0-2])\d{3}$/, tr('Código postal inválido')),
        monthly_income: yup.number().required(tr('Introduce un valor numérico')).nullable(),
        rent_income: yup.number().nullable(),
        other_income: yup.number().nullable(),
    });
    const formik = useFormik({
        initialValues: {
            stability: null,
            expenses: 75,
            wealth: null,
            studies: null,
            years_investing: null,
            experience: null,
            goal: null,
            risk: null,
            attitude: null,
            name: user.name,
            birthday: user.birthday === '' ? null : user.birthday,
            zip_code: user.zipCode,
            monthly_income: null,
            rent_income: null,
            other_income: null,
        },
        validationSchema,
        onSubmit: (values, actions) => {
            const allIncome = (values.monthly_income ?? 0) + (values.rent_income ?? 0) + (values.other_income ?? 0);
            const longerTimeHorizon = objectives.reduce(
                // eslint-disable-next-line @typescript-eslint/no-shadow
                (longerTimeHorizon: number, objective: any) => {
                    if (
                        (objective?.attributes.init_date
                            ? differenceInYears(parseISO(objective.attributes.init_date), new Date())
                            : 0) > longerTimeHorizon
                    ) {
                        // eslint-disable-next-line no-param-reassign
                        longerTimeHorizon = objective?.attributes.init_date
                            ? differenceInYears(parseISO(objective.attributes.init_date), new Date())
                            : 0;
                    }
                    return longerTimeHorizon;
                },
                0
            );
            const annualGrossIncome = allIncome * 12;
            const birthdayFinal = parseISO(values.birthday);
            const userAge = birthdayFinal ? differenceInYears(new Date(), birthdayFinal) : 0;
            const totalInitialInvestment = pensionPlan + initialInvestment;
            const wealth = values.wealth === null ? 0 : values.wealth;
            const investment = (totalInitialInvestment / (values.wealth === null ? 0 : values.wealth)) * 100;
            const answers = {
                stability: values.stability,
                studies: values.studies,
                years_investing: values.years_investing,
                experience: values.experience,
                goal: values.goal,
                risk: values.risk,
                attitude: values.attitude,
                expenses:
                    // eslint-disable-next-line no-nested-ternary
                    values.expenses < 25 ? 1 : values.expenses > 75 ? 3 : 2,
                age:
                    // eslint-disable-next-line no-nested-ternary
                    userAge < 35
                        ? 1
                        : // eslint-disable-next-line no-nested-ternary
                        userAge > 67
                        ? 4
                        : userAge >= 35 && userAge < 55
                        ? 2
                        : 3,
                income:
                    // eslint-disable-next-line no-nested-ternary
                    annualGrossIncome < 50000
                        ? 1
                        : // eslint-disable-next-line no-nested-ternary
                        annualGrossIncome > 600000
                        ? 4
                        : annualGrossIncome >= 50000 && annualGrossIncome <= 300000
                        ? 2
                        : 3,
                wealth:
                    // eslint-disable-next-line no-nested-ternary
                    wealth < 25000
                        ? 1
                        : // eslint-disable-next-line no-nested-ternary
                        wealth > 100000
                        ? 4
                        : wealth >= 25000 && wealth <= 50000
                        ? 2
                        : 3,
                investment:
                    // eslint-disable-next-line no-nested-ternary
                    investment < 25
                        ? 1
                        : // eslint-disable-next-line no-nested-ternary
                        investment > 75
                        ? 4
                        : investment >= 25 && investment <= 50
                        ? 2
                        : 3,
                horizon:
                    // eslint-disable-next-line no-nested-ternary
                    longerTimeHorizon < 3
                        ? 1
                        : // eslint-disable-next-line no-nested-ternary
                        longerTimeHorizon > 10
                        ? 4
                        : longerTimeHorizon >= 3 && longerTimeHorizon <= 5
                        ? 2
                        : 3,
            };
            const incomeQuestion = tr('¿Cuáles son aproximadamente tus ingresos brutos anuales?');
            const incomeQuestionId =
                questions.find((question) => question.attributes.question === incomeQuestion)?.id ?? '0';
            const investmentQuestion = tpl(
                tr('¿Qué porcentaje de tu patrimonio representa la cantidad que quieres invertir en {entity}?'),
                {entity: 'Finanbest'}
            );
            const investmentQuestionId =
                questions.find((question) => question.attributes.question === investmentQuestion)?.id ?? '0';
            const horizonQuestion = tr('¿A qué plazo está prevista tu inversión?');
            const horizonQuestionId =
                questions.find((question) => question.attributes.question === horizonQuestion)?.id ?? '0';
            const ageQuestion = tr('¿Qué edad tienes?');
            const ageQuestionId = questions.find((question) => question.attributes.question === ageQuestion)?.id ?? '0';
            const ageRes = postQuestion(parseInt(ageQuestionId, 10), answers.age);
            const horizonRes = postQuestion(parseInt(horizonQuestionId, 10), answers.horizon);
            const incomeRes = postQuestion(parseInt(incomeQuestionId, 10), answers.income);
            const investmentRes = postQuestion(parseInt(investmentQuestionId, 10), answers.investment);
            Promise.all([horizonRes, ageRes, incomeRes, investmentRes])
                .then(() => {
                    getRiskProfile(testId)
                        .then((response) => {
                            const data = {
                                profile_id: response.attributes.risk_profile_id,
                                max_profile_id: response.attributes.risk_profile_id,
                                external_profile_id: response.id,
                            };
                            dispatch(setRiskProfile(data));
                            if (finish) {
                                finish();
                                formik.setSubmitting(false);
                                hideShowTest();
                            } else {
                                getAllRiskProfilesApi()
                                    // eslint-disable-next-line @typescript-eslint/no-shadow
                                    .then((response) => {
                                        // @ts-ignore
                                        setRiskProfileArray(response);
                                        actions.setSubmitting(false);
                                    })
                                    .catch(async (e) => {
                                        const message = await getErrorMessage(e);
                                        dispatch(
                                            addAlert({
                                                message,
                                                isError: true,
                                                isOpen: true,
                                            })
                                        );
                                        actions.setSubmitting(false);
                                    });
                            }
                        })
                        .catch(async (e) => {
                            const message = await getErrorMessage(e);
                            dispatch(
                                addAlert({
                                    message,
                                    isError: true,
                                    isOpen: true,
                                })
                            );
                            actions.setSubmitting(false);
                        });
                })
                .catch(async (e) => {
                    const message = await getErrorMessage(e);
                    dispatch(
                        addAlert({
                            message,
                            isError: true,
                            isOpen: true,
                        })
                    );
                    actions.setSubmitting(false);
                });
        },
    });
    const handleSubmitQuestion = (value?: any) => {
        setLoading(true);
        const values = value ?? formik.values;
        const {name} = riskProfileQuestionsByQuestionIndex[questionIndex];

        const allIncome = (values.monthly_income ?? 0) + (values.rent_income ?? 0) + (values.other_income ?? 0);
        const longerTimeHorizon = objectives.reduce(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            (longerTimeHorizon: number, objective: any) => {
                if (
                    (objective?.attributes.init_date
                        ? differenceInYears(parseISO(objective.attributes.init_date), new Date())
                        : 0) > longerTimeHorizon
                ) {
                    // eslint-disable-next-line no-param-reassign
                    longerTimeHorizon = objective?.attributes.init_date
                        ? differenceInYears(parseISO(objective.attributes.init_date), new Date())
                        : 0;
                }
                return longerTimeHorizon;
            },
            0
        );
        const annualGrossIncome = allIncome * 12;
        const birthdayFinal = parseISO(values.birthday);
        const userAge = birthdayFinal ? differenceInYears(new Date(), birthdayFinal) : 0;
        const totalInitialInvestment = pensionPlan + initialInvestment;
        const wealth = values.wealth === null ? 0 : values.wealth;
        const investment = (totalInitialInvestment / (values.wealth === null ? 0 : values.wealth)) * 100;

        const answers = {
            stability: values.stability,
            studies: values.studies,
            years_investing: values.years_investing,
            experience: values.experience,
            goal: values.goal,
            risk: values.risk,
            attitude: values.attitude,
            // eslint-disable-next-line no-nested-ternary
            expenses: values.expenses < 25 ? 1 : values.expenses > 75 ? 3 : 2,
            age:
                // eslint-disable-next-line no-nested-ternary
                userAge < 35
                    ? 1
                    : // eslint-disable-next-line no-nested-ternary
                    userAge > 67
                    ? 4
                    : userAge >= 35 && userAge < 55
                    ? 2
                    : 3,
            income:
                // eslint-disable-next-line no-nested-ternary
                annualGrossIncome < 50000
                    ? 1
                    : // eslint-disable-next-line no-nested-ternary
                    annualGrossIncome > 600000
                    ? 4
                    : annualGrossIncome >= 50000 && annualGrossIncome <= 300000
                    ? 2
                    : 3,
            wealth:
                // eslint-disable-next-line no-nested-ternary
                wealth < 25000
                    ? 1
                    : // eslint-disable-next-line no-nested-ternary
                    wealth > 100000
                    ? 4
                    : wealth >= 25000 && wealth <= 50000
                    ? 2
                    : 3,
            investment:
                // eslint-disable-next-line no-nested-ternary
                investment < 25
                    ? 1
                    : // eslint-disable-next-line no-nested-ternary
                    investment > 75
                    ? 4
                    : investment >= 25 && investment <= 50
                    ? 2
                    : 3,
            horizon:
                // eslint-disable-next-line no-nested-ternary
                longerTimeHorizon < 3
                    ? 1
                    : // eslint-disable-next-line no-nested-ternary
                    longerTimeHorizon > 10
                    ? 4
                    : longerTimeHorizon >= 3 && longerTimeHorizon <= 5
                    ? 2
                    : 3,
        };
        // @ts-ignore
        postQuestion(
            parseInt(questions[apiQuestionIndex]?.id ?? 0, 10),
            // @ts-ignore
            answers[name]
        )
            .then(() => {
                if (questionIndex < riskProfileQuestionsByQuestionIndex.length) {
                    setQuestionIndex(questionIndex + 1);
                }
                setLoading(false);
            })
            .catch(async (e) => {
                const message = await getErrorMessage(e);
                dispatch(
                    addAlert({
                        message,
                        isError: true,
                        isOpen: true,
                    })
                );
                setLoading(false);
            });
    };
    React.useEffect(() => {
        setLoading(true);
        setHideBirthdayQuestion(user.birthday !== '');

        getObjectives()
            .then((resObjectives) => {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                setObjectives(resObjectives);
                dispatch(setIsAuthDialogOpen(false));
                setLoading(false);
            })
            .catch(async (e) => {
                const message = await getErrorMessage(e);
                dispatch(
                    addAlert({
                        message,
                        isError: true,
                        isOpen: true,
                    })
                );
                setLoading(false);
            });
    }, []);
    const initTestMifid = async () => {
        try {
            const config = await getConfig();
            if (config?.mifid) {
                const res = await getFinancialProviderId();
                const mifidId = await getTestMifidId(res);
                setTestId(mifidId);
                const response = await getQuestionsByMifidId(mifidId);
                setQuestions(response.length > 0 ? response : questionsOfTestMifid.data);
            }
        } catch (e) {
            // @ts-ignore
            const message = await getErrorMessage(e);
            dispatch(
                addAlert({
                    message,
                    isError: true,
                    isOpen: true,
                })
            );
        }
    };
    React.useEffect(() => {
        initTestMifid().then();
    }, []);
    React.useEffect(() => {
        const currentQuestion = riskProfileQuestionsByQuestionIndex[questionIndex]?.question;
        const currentApiQuestionIndex = questions.findIndex(
            (question) => question.attributes.question === currentQuestion
        );
        if (currentApiQuestionIndex > 0) {
            setApiQuestionIndex(currentApiQuestionIndex);
        } else if (questionIndex < 2) {
            setApiQuestionIndex(questionIndex);
        }
    }, [questionIndex]);
    const handleNextClick = () => {
        if (questionIndex === 0) {
            formik.validateForm(formik.values).then((err: any) => {
                if (err?.birthday === undefined && err?.name === undefined && err?.zip_code === undefined) {
                    formik.setSubmitting(true);
                    // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-shadow
                    const {birthday, name, zip_code} = formik.values;

                    const finalBirthday = birthday ? format(parseISO(birthday), 'yyyy-MM-dd') : '';
                    dispatch(
                        setUser({
                            name,
                            zipCode: zip_code,
                            birthday: finalBirthday,
                        })
                    );
                    const storage = globalThis?.sessionStorage;
                    if (storage) {
                        const token = storage.getItem('token');
                        getUserIdApi(token ?? '').then((res) => {
                            if (!res?.attributes?.user_id || res?.attributes?.user_id === '') {
                                dispatch(
                                    addAlert({
                                        message: tr('Usuario inexistente, comience el proceso de nuevo'),
                                        isError: true,
                                        isOpen: true,
                                    })
                                );
                                dispatch(setUser({name: '', zipCode: '', birthday: ''}));
                                formik.setSubmitting(false);
                                router('/');
                            } else {
                                patchExistingUserNamePcAndBirthday(
                                    res.attributes.user_id ? res.attributes.user_id.toString() : '',
                                    finalBirthday,
                                    zip_code,
                                    name
                                )
                                    .then(() => {
                                        if (process.env.REACT_APP_PERFIL_NEXT_STEP_PATH) {
                                            router(`/${process.env.REACT_APP_PERFIL_NEXT_STEP_PATH}`);
                                        } else {
                                            setQuestionIndex(questionIndex + 1);
                                        }
                                        formik.setFieldError('monthly_income', undefined);
                                        formik.setSubmitting(false);
                                    })
                                    .catch(async (e) => {
                                        const message = await getErrorMessage(e);
                                        dispatch(
                                            addAlert({
                                                message,
                                                isError: true,
                                                isOpen: true,
                                            })
                                        );
                                        formik.setSubmitting(false);
                                    });
                            }
                        });
                    }
                }
            });
        } else if (questionIndex === 1) {
            formik.validateForm(formik.values).then((err: any) => {
                if (
                    err?.monthly_income === undefined &&
                    err?.rent_income === undefined &&
                    err?.other_income === undefined
                ) {
                    formik.setSubmitting(true);
                    setQuestionIndex(questionIndex + 1);
                    formik.setFieldError('wealth', undefined);
                    formik.setSubmitting(false);
                }
            });
        } else if (questionIndex === profileQuestionNameOrder.length) {
            router('/propuesta/resultado');
        } else {
            formik.setFieldTouched(profileQuestionNameOrder[questionIndex], true, true).then((err) => {
                if (
                    // eslint-disable-next-line no-prototype-builtins
                    !err?.hasOwnProperty(profileQuestionNameOrder[questionIndex])
                ) {
                    formik.setErrors({});
                    if (questionIndex === 10) {
                        handleSubmitQuestion();
                        formik.handleSubmit();
                    } else {
                        handleSubmitQuestion();
                    }
                }
            });
        }
    };
    const profileFormQuestionMainTitle = [
        tr('Introduce tus datos'),
        tr('Ingresos del hogar'),
        tr(`Estabilidad financiera`),
        tr('Gastos fijos'),
        tr('Patrimonio financiero'),
        tr(`Formación`),
        tr('Experiencia inversora'),
        tr('Experiencia inversora'),
        tr('Rentabilidad vs. Riesgo'),
        tr('Tolerancia al riesgo'),
        tr(`Tolerancia al riesgo`),
    ];
    return (
        <div className={perfil.container}>
            <PageLoading open={formik.isSubmitting || loading} />
            <inv-grid-row>
                <inv-grid-col class="col" style={{display: 'flex', justifyContent: 'center'}}>
                    <inv-text-l
                        style={{
                            '--inv-text-l-color': 'var(--color-tertiary)',
                            '--inv-text-l-font-weight': 600,
                        }}
                    >
                        {profileFormQuestionMainTitle[questionIndex]}
                    </inv-text-l>
                </inv-grid-col>
            </inv-grid-row>
            {
                profileFormQuestionTitle(
                    (formik.values.monthly_income ?? 0) +
                        (formik.values.rent_income ?? 0) +
                        (formik.values.other_income ?? 0)
                )[questionIndex]
            }
            <inv-grid-row class={perfil.formContainer}>
                <inv-grid-col class="col-12">
                    <RiskProfileForm
                        monthly_income={
                            (formik.values.monthly_income ?? 0) +
                            (formik.values.rent_income ?? 0) +
                            (formik.values.other_income ?? 0)
                        }
                        formik={formik}
                        questionIndex={questionIndex}
                        hideBirthdayQuestion={hideBirthdayQuestion}
                        handleSubmitQuestion={handleSubmitQuestion}
                        riskProfileArray={riskProfileArray}
                    />
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row class={`${perfil.divider}`}>
                <inv-grid-col class="col-12">
                    <div style={{maxWidth: 'calc(100% - var(--inv-grid-gutter-x))'}}>
                        <SolidDividers orientation="horizontal" />
                    </div>
                </inv-grid-col>
            </inv-grid-row>
            <inv-grid-row
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '1.2em 0 0',
                    '--inv-grid-gutter-x': 0,
                }}
            >
                <inv-grid-col class={`${goalFooterMeta.buttonElement1} col-12 col-sm-4`}>
                    {questionIndex < profileQuestionNameOrder.length && (
                        <inv-button
                            onClick={() => {
                                if (questionIndex === 0) {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                    goBack ? goBack() : router('/perfil/inicio');
                                } else {
                                    setQuestionIndex(questionIndex - 1);
                                }
                            }}
                            class="button button--secondary-contained full-width"
                        >
                            {tr('Atrás')}
                        </inv-button>
                    )}
                </inv-grid-col>
                <inv-grid-col
                    class={`col-auto ${goalFooterMeta.buttonElement2}`}
                    style={{display: 'flex', alignItems: 'center'}}
                >
                    <DotStepsFragment
                        steps={
                            process.env.REACT_APP_PERFIL_TEST_STEPS
                                ? parseInt(process.env.REACT_APP_PERFIL_TEST_STEPS, 10)
                                : 12
                        }
                        activeStep={questionIndex}
                    />
                </inv-grid-col>
                <inv-grid-col class={`${goalFooterMeta.buttonElement3} col-12 col-sm-4`}>
                    <inv-button class="button button--primary-contained full-width" onClick={handleNextClick}>
                        {tr('Continuar')}
                    </inv-button>
                </inv-grid-col>
            </inv-grid-row>
        </div>
    );
}
export default Perfil;
